import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NavBarService } from '../navbar.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LocalizeRouterModule, LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
@Component({
  standalone: true,
  imports: [TranslocoModule, MatProgressSpinnerModule,  CommonModule, MatIconModule, LocalizeRouterModule, RouterModule],
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent{

  videoLoaded = false; // Flag to determine if video is loaded

  @ViewChild('videoElement') videoElementRef!: ElementRef<HTMLVideoElement>;

  onVideoLoaded() {
    // Set videoLoaded to true when video can start playing through without stopping
    this.videoLoaded = true;
  }
  
  constructor
  (
    private translate:TranslocoService,
    private router :Router,
    private navBarService:NavBarService,
    private localize:LocalizeRouterService
  ) {
    this.navBarService.show()
  }

  onWindowScroll()
  {
    if(!this.videoLoaded) return
    const path = this.localize.translateRoute('/menu')
    this.router.navigate([path])
  }
}

