<div class="bgRental" (wheel)="onWindowScroll()">
    <div id="pageTopRectanglesRow">
      <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/menu' | localize" routerLinkActive="active">
        {{"backSecond" | transloco }}</div>
      <div class="pageTopMarginBox">
      </div>
      <div class="centered pageMarginBox BoxFadeInFromRight">
        <app-language-selector></app-language-selector>
      </div>
    </div>
    <div id="pageRectanglesRow">
      <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
      <div id="content" class="pageContentBox">

        <div class="mx-auto p-8">
            <h1 class="text-3xl font-bold text-center mb-8">{{'co_oferuje_nasza_wypozyczalnia' | transloco}}</h1>
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4  gap-8">
    
                <!-- Karta 1: Rower -->
                <div class="bg-white shadow-lg rounded-lg overflow-hidden pt-4">
                    <div class="w-full h-48 min-h-48 max-h-48 bg-contain bg-no-repeat bg-center" [style.background-image]="'url(/assets/images/rental/rower-trekkingowy.jpg)'" alt="Rower trekkingowy"></div>
                    <div class="p-6">
                        <h2 class="text-lg md:text-xl font-semibold mb-2">{{'rower_trekkingowy' | transloco}}</h2>
                        <p class="text-xl md:text-2xl text-gray-700 mb-8">{{'wyogndy_rower_trekkingowy_idealny_do_wypraw_promenada_i_sciezkami_rowerowymi' | transloco}}</p>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 20 zł / EUR 5.00 - {{'jedna_godzina' | transloco}}</div>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 40 zł / EUR 10.00 - {{'dwie_godziny' | transloco}}</div>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 60 zł / EUR 15.00 - {{'doba' | transloco}}</div>
                    </div>
                </div>

                <!-- Karta 1: Rower -->
                <div class="bg-white shadow-lg rounded-lg overflow-hidden pt-4">
                    <div class="w-full h-48 min-h-48 max-h-48 bg-contain bg-no-repeat bg-center" [style.background-image]="'url(/assets/images/rental/przyczepka-rowerowa.jpg)'" alt="Przyczepka rowerowa"></div>
                    <div class="p-6">
                        <h2 class="text-lg md:text-xl font-semibold mb-2">{{'przyczepka_rowerowa' | transloco}}</h2>
                        <p class="text-xl md:text-2xl text-gray-700 mb-8">{{'lekka_przyczepka_rowerowa_dla_1_lub_2_dzieci' | transloco}}</p>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 20 zł / EUR 5.00 - {{'jedna_godzina' | transloco}}</div>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 40 zł / EUR 10.00 - {{'dwie_godziny' | transloco}}</div>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 60 zł / EUR 15.00 - {{'doba' | transloco}}</div>
                    </div>
                </div>
    
                <!-- Karta 2: Quad -->
                <div class="bg-white shadow-xl rounded-lg overflow-hidden pt-4">
                    <div class="w-full h-48 min-h-48 max-h-48 bg-contain bg-no-repeat bg-center" [style.background-image]="'url(/assets/images/rental/goes-500l.png)'" alt="Quad"></div>
                    <div class="p-6">
                        <h2 class="text-lg md:text-xl font-semibold mb-2">Quad 4x4</h2>
                        <p class="text-xl md:text-2xl text-gray-700 mb-8">{{'duzy_2_osobowy_quad_na_wycieczki_po_okolicznych_miejscowosciach' | transloco}}</p>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 120 zł / EUR 30.00 - {{'jedna_godzina' | transloco}}</div>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 200 zł / EUR 50.00 - {{'dwie_godziny' | transloco}}</div>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 400 zł / EUR 100.00 - {{'doba' | transloco}}</div>
                    </div>
                </div>
    
                <!-- Karta 3: Skuter Wodny -->
                <div class="bg-white shadow-lg rounded-lg overflow-hidden pt-4">
                    <div class="w-full h-48 min-h-48 max-h-48 bg-contain bg-no-repeat bg-center" [style.background-image]="'url(/assets/images/rental/sea-doo-spark.jpg)'" alt="Skuter wodny - Sea-Doo Spark"></div>
                    <div class="p-6">
                        <h2 class="text-lg md:text-xl font-semibold mb-2">{{'skuter_wodny' | transloco}}</h2>
                        <p class="text-xl md:text-2xl text-gray-700 mb-8">{{'szybki_skuter_wodny_idealny_do_pelnych_emocji_przygod_nad_baltykiem' | transloco}}</p>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4">{{'obowiazuje_od_maja_do_pazdziernika' | transloco}}</div>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 200 zł / EUR 50.00 - 20 {{'minut' | transloco}} </div>
                        <div class="text-sm md:text-base text-gray-900 font-medium mb-4"><span class="font-semibold">{{'cena' | transloco}}:</span> 450 zł / EUR 115.00 - {{'jedna_godzina' | transloco}}</div>
                    </div>
                </div>
    

            </div>

            <div class="p-6">
                <p class="text-sm">{{'prosimy_o_wczesniejsze_rezerwacje_w_celu_zapewnienia_dostepu_do_wybranych_atrakcji' | transloco}}</p>   

                <h1 class="text-3xl font-bold my-8">{{'dodatkowe_informacje' | transloco}}</h1>
                <div class="text-xl">
                    {{'wypozyczenie_quada_lub_skutera_wodnego_nalezy_miec_ukonczone_18_lat' | transloco}}
                    <br/> 
                    <br/> 
                    {{'skuter_wodny_jest_podstawiany_w_konkretnie_wyznaczone_miejsce_na_plazy_tuz_przy_hotelu' | transloco}}
                </div>
                
                <h1 class="text-2xl font-bold my-8">{{'regulamin_wypozyczenia_roweru' | transloco}}</h1>

                <div class="text-xl max-w-100">
                    1. {{'do_roweru_wypozyczamy_bezplatnie_kask_oraz_blokade' | transloco}}<br/>
                    2. {{'wypozyczajacy_nie_ponosi_odpowiedzialnosci' | transloco}}<br/>
                    3. {{'osoba_wypozyczajaca_bierze_pelna_odpowiedzialnosc' | transloco}}<br/>
                    4. {{'osoba_wypozyczajaca_nie_moze_uzyczac_lub_wynajmowac_sprzetu_osobom_trzecim' | transloco}}<br/>
                    5. {{'kary' | transloco}}: <br/>
                        - {{'utrata_lub_zniszczenie_roweru' | transloco }} - 1500 zł <br/>
                        - {{'utrata_lub_zniszczenie_przyczepki' | transloco }} - 1200 zł <br/>
                        - {{'utrata_lub_zniszczenie_kasku' | transloco }} - 100 zł <br/>
                        - {{'utrata_lub_zniszczenie_zapiecia' | transloco }} - 50 zł <br/>
                        - {{'utrata_kluczyka_do_zapiecia' | transloco }} - 40 zł <br/>
                </div>
               
                

                <h1 class="text-2xl font-bold my-8">{{'regulamin_wypozyczenia_quada' | transloco }}</h1>

                <div class="text-xl max-w-100">
                    1. {{'aby_wypozyczyc_quada_nalezy_okazac_prawo_jazdy_z_kategoria_b' | transloco }}<br/>
                    2. {{'przed_wypozyczeniem_quada_nastapi_szkolenie' | transloco}}<br/>
                    3. {{'do_quadow_wypozyczamy_bezplatnie_kask' | transloco}}<br/>
                    4. {{'zalecamy_zglosic_chec_wypozyczenia_z_co_najmniej_jednodniowym_wyprzedzeniem' | transloco}}<br/>
                    5. {{'quad_wypozyczony_jest_z_pelnym_zbiornikiem_paliwa' | transloco}}<br/>
                    6. {{'wypozyczajacy_nie_ponosi_odpowiedzialnosci' | transloco}}<br/>
                    7. {{'osoba_wypozyczajaca_bierze_pelna_odpowiedzialnosc' | transloco}}<br/>
                    8. {{'osoba_wypozyczajaca_nie_moze_uzyczac_lub_wynajmowac_sprzetu_osobom_trzecim' | transloco}}<br/>
                    9. {{'kary' | transloco}}: <br/>
                        - {{'utrata_lub_zniszczenie_w_zaleznosci_od_szkody' | transloco}}<br/>
                        - {{'utrata_lub_zniszczenie_kasku' | transloco}} - 500 zł <br/>
                        - {{'utrata_kluczyka' | transloco}} - 1000 zł <br/>
                        - {{'niezatankowanie_paliwa_do_pelnego_zbiornika_przed_oddaniem' | transloco}} - 100 zł <br/>
                </div>
               

                <h1 class="text-2xl font-bold my-8">{{'regulamin_wypozyczenia_skutera_wodnego' | transloco }}</h1>

                <div class="text-xl max-w-100">
                    1. {{'przed_wypozyczeniem_skutera_wodnego_nastapi_szkolenie' | transloco}}<br/>
                    2. {{'zalecamy_zglosic_chec_wypozyczenia_z_co_najmniej_jednodniowym_wyprzedzeniem' | transloco}}<br/>
                    3. {{'do_skutera_wypozyczamy_bezplatnie_kamizelke_asekuracyjna' | transloco}}<br/>
                    4. {{'wypozyczajacy_nie_ponosi_odpowiedzialnosci' | transloco}}<br/>
                    5. {{'osoba_wypozyczajaca_bierze_pelna_odpowiedzialnosc' | transloco}}<br/>
                    6. {{'osoba_wypozyczajaca_nie_moze_uzyczac_lub_wynajmowac_sprzetu_osobom_trzecim' | transloco}}<br/>
                    7. {{'kary' | transloco}}: <br/>
                        - {{'utrata_lub_zniszczenie_w_zaleznosci_od_szkody' | transloco}}<br/>
                        - {{'utrata_kluczyka' | transloco}} - 1000 zł <br/>
                        - {{'utrata_kamizelki' | transloco}} - 300 zł <br/>
                </div>
            </div>

        </div>


      </div>
      <div class="pageSideMarginBox BoxFadeInFromRight"></div>
    </div>
  </div>