<div class="bgReception" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/menu' | localize" routerLinkActive="active">
      {{"backSecond" | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox" style="padding: 20px 20px;">
      <div class="row">
        <div class="col-xs-12 ">{{"callOrMail" | transloco }}
          <br>
          <br>
          <span style="color: gray;font-size:18px;">{{"waitingForYourMessage" | transloco }}</span>
        </div>
        <div class="col-xs-12" style="margin-top: 20px;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3" width="50" height="30">
            <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000" />
            <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00" />
            <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" id="Flag of Poland" viewBox="0 0 16 10">
            <rect width="16" height="10" fill="#fff" />
            <rect width="16" height="5" fill="#dc143c" y="5" />
          </svg>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-xs-12 col-md-6" style="font-size: 18px;">
          <br>
          Tel. <a href="tel:943515661">(94) 35 15 661</a>
          <br> Tel. <a href="tel:+48604504112">+48 604 504 112</a>
          <br>
          <br>
          {{"email" | transloco }} <br /> <a href="mailto:recepcja@borgata.pl">recepcja&#64;borgata.pl</a> 
          <br>
        </div>
        <div class="col-xs-12 col-md-6" style="font-size: 18px;">
          <br>
          <b>Borgata</b> <br>
          {{"streetShort" | transloco }} Spokojna 7 <br>
          78-111 Ustronie Morskie <br>
          {{"poland" | transloco}}
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-xs-12" style="min-height: 400px;">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1166.3891456312301!2d15.768810999999998!3d54.219413!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1b96980a10161170!2sBorgata+*2A*2A*2A!5e0!3m2!1spl!2spl!4v1528776527272"
            width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>
          <div style="height: 100px;"></div>
        </div>

        <img src="/assets/images/pfr.png" height="120px" width="100%" style="max-width:500px;" alt="">
      </div>

    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>