import { Component, OnInit } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
standalone: true,
imports: [TranslocoModule, CommonModule],
  selector: 'app-we-provide-winter',
  templateUrl: './we-provide-winter.component.html',
  styleUrls: ['./we-provide-winter.component.css']
})
export class WeProvideWinterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
