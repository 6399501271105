import { Component, OnInit, HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NavBarService } from "../navbar.service";
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RouterModule } from "@angular/router";
import { LocalizeRouterModule } from "@penleychan/ngx-transloco-router";

@Component({
standalone: true,
imports: [TranslocoModule, CommonModule, LocalizeRouterModule, RouterModule],
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.css"]
})
export class NewsComponent {
  news: any;

  constructor(public navBarService:NavBarService,private translate:TranslocoService, public http: HttpClient) {
    
    this.navBarService.hide()
    http
      .get("https://borgata-1528757340514.firebaseio.com/news.json")
      .subscribe(
        data => {
          this.news = data;
          this.news.reverse()
        },
        err => console.log(err),
        () => {}
      );
  }

  onWindowScroll() {
    let firstRow = document.getElementById("pageTopRectanglesRow");
    firstRow.style.height = "10%";
    firstRow.style.transition = "1s";
    let secondRow = document.getElementById("pageRectanglesRow");
    secondRow.style.height = "90%";
  }
}
