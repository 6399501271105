import { Component, OnInit } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NavBarService } from '../navbar.service';
import { CommonModule } from '@angular/common';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';
import { RouterModule } from '@angular/router';


@Component({
standalone: true,
imports: [TranslocoModule, CommonModule, LocalizeRouterModule, RouterModule],
  selector: 'app-virtual-walk',
  templateUrl: './virtual-walk.component.html',
  styleUrls: ['./virtual-walk.component.css']
})
export class VirtualWalkComponent {

  constructor(
    public navBarService:NavBarService,
    public translate:TranslocoService) { 
      this.navBarService.hide()
    }

  onWindowScroll()
  {
    let firstRow = document.getElementById("pageTopRectanglesRow")
    firstRow.style.height = "10%";
    firstRow.style.transition = "1s";
    let secondRow = document.getElementById("pageRectanglesRow")
    secondRow.style.height = "90%"
  }

}
