import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd, RouterOutlet, ActivatedRoute } from '@angular/router';
import { MainNavComponent } from './main-nav/main-nav.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { DataService } from './data.service';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { filter } from 'rxjs';

@Component({
  standalone: true,
  imports: [RouterOutlet, MainNavComponent, TranslocoModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  isAuthenticated:boolean;

  weather:any;


  constructor
  (
    public httpClient: HttpClient,
    private router:Router,
    private dataService:DataService,
    private translate: TranslocoService,
    private title: Title, 
    private meta: Meta,
    private activatedRoute: ActivatedRoute
  )
  {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.traverseRouteTree(this.activatedRoute.firstChild);
    });

  }

  private traverseRouteTree(route: ActivatedRoute | null): void {
    if (!route) {
      return;
    }

    route.data.subscribe((data) => {

      this._updateTitle(data?.translationKey || '');

      const description = this.translate.translate(data?.translationKey + '.description');
      if(description == '') { console.warn("Translation not found for: ", description)}
      const metaDefinition: MetaDefinition = { name: 'description', content: description };
      this.updateMetaTag(metaDefinition);
    });

    // Recursively traverse the route tree
    this.traverseRouteTree(route.firstChild);
  }

  ngOnInit(): void {
    this.dataService.getVouchers()

    // this._updateSEO()
  }

  private _updateTitle(translationKey: string): void {
    const translatedTitle = this.translate.translate(translationKey + '.title');

    if(translatedTitle == '') { console.warn("Translation not found for: ", translationKey)}
    this.title.setTitle(translatedTitle);
  }

  private updateMetaTag(tag: MetaDefinition): void {
    const existingTag = this.meta.getTag(`name='${tag.name}'`);

    if (existingTag) {
      this.meta.removeTag(`name='${tag.name}'`);
    }

    this.meta.addTag(tag);
  }
  // private _updateSEO() {

  //   this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
  //     const route = this.activatedRoute;
  //     while (route.firstChild) {
  //       route.firstChild.data.subscribe((data) => {

  //         this._updateTitle(data?.translationKey || '');

  //         const description = this.translate.translate(data?.translationKey + '.description')
  //         const metaDefinition = { name: 'description', content: description };
  //         this.updateMetaTag(metaDefinition);
  //       });
  //       route.firstChild = route.firstChild.firstChild;
  //     }
  //   });
    
    // this.route.data.subscribe((data) => {
    //   console.log(data)
    //   if(typeof data.translationKey != 'undefined') {
    //     const translationKey = data.translationKey;
    //     const title = this.translate.translate(translationKey + '.title')
    //     this.title.setTitle(title);
  
    //     const description = this.translate.translate(translationKey + '.description')
    //     const metaDefinition = { name: 'description', content: description };
    //     this.updateMetaTag(metaDefinition);
    //     console.log("update meta:", metaDefinition)
    //   } 
    //   console.log(data.translationKey)
    // });
  // }


  // private _updateTitle(translationKey: string): void {

  //   const title = this.translate.translate(translationKey + '.title')
  //   this.title.setTitle(title);
  // }

  // private updateMetaTag(tag: MetaDefinition): void {
  //   const existingTag = this.meta.getTag(`name='${tag.name}'`);
    
  //   if (existingTag) {
  //     this.meta.removeTag(`name='${tag.name}'`);
  //   }
    
  //   this.meta.addTag(tag);
  // }
}