
<div class="bgChristmas" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/occasional-offers' | localize" routerLinkActive="active">{{"backSecond" | transloco}}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h1 class="topic" style="color:#80006b;">{{"packages.christmas" | transloco}} 2023</h1>
      <br/>
      <!-- <div class="alert alert-info" style="font-size: 16px;"><span class="fa fa-exclamation-circle"></span> {{"poolEntryWinterText" | transloco}}</div> -->
      <h3 class="heading">{{"packageIncludes" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"alimentation" | transloco}}</span>
            <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"discount" | transloco}}</span>
            <span class="text">{{"discountTenForSPA" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sauna" | transloco}}</span>
            <span class="text">{{"unlimitedSauna" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"aquapark" | transloco}}</span>
            <span class="text">{{"twoTimesAquaparkAccess" | transloco}}</span>
          </li>
        </ul>
      </div>
      <h3 class="heading">{{"easterProgram" | transloco}}</h3>
      <h4 class="heading" style="margin-left: 35px;">24.12.2023</h4>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"eve" | transloco}}</span>
            <span class="text">{{"christmasDinner" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"carols" | transloco}}</span>
            <span class="text">{{"carolingTogether" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"present" | transloco}}</span>
            <span class="text">{{"giftFromSaintNicholas" | transloco}}</span>
          </li>
        </ul>
      </div>
      <h4 class="heading" style="margin-left: 35px;">25.12.2023 & 26.12.2023</h4>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"specialAlimentation" | transloco}}</span>
            <span class="text">{{"easterAlimentation" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sweetBuffet" | transloco}}</span>
            <span class="text">{{"sweetBuffetText" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"bonfire" | transloco}}</span>
            <span class="text">{{"bonfireText" | transloco}}</span>
          </li>
        </ul>
      </div>
     <app-we-provide-winter></app-we-provide-winter>
      <app-attractions></app-attractions>
      <h3 class="heading">{{"pricePerPerson" | transloco}}</h3>
      <table class="priceTable table table-striped" >
        <tbody>
          <tr>
            <td style="text-align: left;">&nbsp;</td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 23.12 {{"to" | transloco}} 27.12
              </p>
            </td>
          </tr>
          <tr>
            <td class="topicTd" style="text-align: center;">
                {{"fourNights" | transloco}}
            </td>
            <td style="text-align: center;">
              <strong>1490zł</strong>
            </td>
          </tr>
        </tbody>
      </table>
      
      <h3 class="heading">{{"additionalFees" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
              <span class="title">{{"kidsUnderFour" | transloco}}</span>
              <span class="text">{{"free" | transloco}}</span>
            </li>
            <li>
                <span class="title">{{"kidsBetweenFourAndFourteen" | transloco}}</span>
                <span class="text">-50%</span>
              </li>
          <li>
              <span class="title">{{"addictionalDay" | transloco}}</span>
              <span class="text">289,00 zł</span>
            </li>
            <li>
                <span class="title">{{"extraBed" | transloco}}</span>
                <span class="text">1199,00 zł</span>
              </li>
          <li>
            <span class="title">{{"parking" | transloco}}</span>
            <span class="text">30,00 zł / {{"day" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"touristTax" | transloco}}</span>
            <span class="text">2,50 zł {{"day" | transloco}}/{{"person" | transloco}}</span>
          </li>
        </ul>
      </div>
      <additional-informations></additional-informations>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>