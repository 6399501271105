import { Route } from "@angular/router";
import { ContactComponent } from "./contact/contact.component";
import { FilesComponent } from "./files/files.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { HotelComponent } from "./hotel/hotel.component";
import { NewsDetailsComponent } from "./news-details/news-details.component";
import { NewsComponent } from "./news/news.component";
import { ChristmasComponent } from "./occasional-offers/christmas/christmas.component";
import { EasterComponent } from "./occasional-offers/easter/easter.component";
import { NewYearsEveComponent } from "./occasional-offers/new-years-eve/new-years-eve.component";
import { OccasionalOffersComponent } from "./occasional-offers/occasional-offers.component";
import { SummerComponent } from "./occasional-offers/summer/summer.component";
import { WinterHolidaysComponent } from "./occasional-offers/winter-holidays/winter-holidays.component";
import { WinterRecreationComponent } from "./occasional-offers/winter-recreation/winter-recreation.component";
import { FishTastingComponent } from "./organisation-of-events/fish-tasting/fish-tasting.component";
import { OrganisationOfEventsComponent } from "./organisation-of-events/organisation-of-events.component";
import { HealthComponent } from "./packets/health/health.component";
import { MedikurComponent } from "./packets/medikur/medikur.component";
import { MensRelaxComponent } from "./packets/mens-relax/mens-relax.component";
import { PacketsComponent } from "./packets/packets.component";
import { RelaxComponent } from "./packets/relax/relax.component";
import { SeniorComponent } from "./packets/senior/senior.component";
import { SevendaysComponent } from "./packets/sevendays/sevendays.component";
import { WeekendspaComponent } from "./packets/weekendspa/weekendspa.component";
import { WomensrelaxComponent } from "./packets/womensrelax/womensrelax.component";
import { PricesComponent } from "./prices/prices.component";
import { RectanglesComponent } from "./rectangles/rectangles.component";
import { CosmeticalTreatmentsComponent } from "./spa/cosmetical-treatments/cosmetical-treatments.component";
import { MassagesComponent } from "./spa/massages/massages.component";
import { PhysicalTreatmentsComponent } from "./spa/physical-treatments/physical-treatments.component";
import { SpaComponent } from "./spa/spa.component";
import { VirtualWalkComponent } from "./virtual-walk/virtual-walk.component";
import { VouchersComponent } from "./vouchers/vouchers.component";
import { LanguageResolver } from "./shared/resolvers/language.resolver";
import { RentalComponent } from "./rental/rental.component";

export const appRoutes: Route[] = [
  {
    path: '',
    children: [
      { path: '', data: { translationKey: 'seo.main' }, loadChildren: () => import('app/hotel/hotel.routes') },
      { path: 'menu', component: RectanglesComponent,  data: { translationKey: 'seo.menu' } },
      { path: 'news', component: NewsComponent, data: { translationKey: 'seo.main' }},
      { path: 'vouchers', component: VouchersComponent, data: { translationKey: 'seo.vouchers' } },
      { path: 'news-details/:id', component: NewsDetailsComponent, data: { translationKey: 'seo.main' } },
      { path: 'gallery', component: GalleryComponent, data: { translationKey: 'seo.gallery' } },
      { path: 'rental', component: RentalComponent, data: { translationKey: 'seo.rental' } },
      { path: 'prices', component: PricesComponent, data: { translationKey: 'seo.prices' } },
      { path: 'contact', component: ContactComponent, data: { translationKey: 'seo.contact' } },
      { path: 'files', component: FilesComponent, data: { translationKey: 'seo.files' } },
      { path: 'spa', component: SpaComponent, data: { translationKey: 'seo.spa' } },
      { path: 'spa/massages', component: MassagesComponent, data: { translationKey: 'seo.massages' } },
      { path: 'spa/cosmetical-treatments', component: CosmeticalTreatmentsComponent, data: { translationKey: 'seo.cosmetical-treatments' } },
      { path: 'spa/physical-treatments', component: PhysicalTreatmentsComponent, data: { translationKey: 'seo.physical-treatments' } },
      { path: 'virtual-walk', component: VirtualWalkComponent, data: { translationKey: 'seo.virtual-walk' } },
      { path: 'occasional-offers', component: OccasionalOffersComponent, data: { translationKey: 'seo.occasional-offers' } },
      { path: 'packets', component: PacketsComponent, data: { translationKey: 'seo.packets' } },
      { path: 'packets/health', component: HealthComponent, data: { translationKey: 'seo.health' } },
      { path: 'packets/senior', component: SeniorComponent, data: { translationKey: 'seo.senior' } },
      { path: 'packets/medikur', component: MedikurComponent, data: { translationKey: 'seo.medikur' } },
      { path: 'packets/relax', component: RelaxComponent, data: { translationKey: 'seo.relax' } },
      { path: 'packets/weekendspa', component: WeekendspaComponent, data: { translationKey: 'seo.weekendspa' } },
      { path: 'packets/ladies-relax', component: WomensrelaxComponent, data: { translationKey: 'seo.ladies-relax' } },
      { path: 'packets/mens-relax', component: MensRelaxComponent, data: { translationKey: 'seo.mens-relax' } },
      { path: 'occasional-offers/easter', component: EasterComponent, data: { translationKey: 'seo.easter' } },
      { path: 'occasional-offers/christmas', component: ChristmasComponent, data: { translationKey: 'seo.christmas' } },
      { path: 'occasional-offers/winter-holidays', component: WinterHolidaysComponent, data: { translationKey: 'seo.winter-holidays' } },
      { path: 'occasional-offers/new-years-eve', component: NewYearsEveComponent, data: { translationKey: 'seo.new-years-eve' } },
      { path: 'occasional-offers/summer', component: SummerComponent, data: { translationKey: 'seo.summer' } },
      { path: 'occasional-offers/winter-recreation', component: WinterRecreationComponent, data: { translationKey: 'seo.winter-recreation' } },
      { path: 'organisation-of-events', component: OrganisationOfEventsComponent, data: { translationKey: 'seo.organisation-of-events' } },
      { path: 'organisation-of-events/fish-tasting', component: FishTastingComponent, data: { translationKey: 'seo.fish-tasting' } },
      { path: 'packets/sevendays', component: SevendaysComponent, data: { translationKey: 'seo.sevendays' } },
    ]
  },
  { path: '**', resolve: { language: LanguageResolver }, component: HotelComponent, data: { translationKey: 'seo.main' } } 
];