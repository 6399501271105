import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, RouterModule } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { NavBarService } from "../navbar.service";
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LocalizeRouterModule } from "@penleychan/ngx-transloco-router";

@Component({
  standalone: true,
  imports: [TranslocoModule, CommonModule, LocalizeRouterModule, RouterModule],
  selector: "app-news-details",
  templateUrl: "./news-details.component.html",
  styleUrls: ["./news-details.component.css"]
})

export class NewsDetailsComponent {
  id: any;
  newsData: any
  constructor(
    public translate: TranslocoService,
    private route: ActivatedRoute,
    public navBarService: NavBarService,
    public http: HttpClient
  ) {
    this.navBarService.hide()
    this.route.params.subscribe(
      (params: Params) => {
        this.id = params["id"];
        this.getNewsData()
      }
    );
  }



  getNewsData() {
    let id = this.id
    this.http.get('https://borgata-1528757340514.firebaseio.com/news/' + id + '.json').subscribe(
      data => {
        this.newsData = data
        console.warn(data)
      },
      err => console.log(err),
      () => { }
    )
  }
  onWindowScroll() {

    let firstRow = document.getElementById("pageTopRectanglesRow");
    firstRow.style.height = "10%";
    firstRow.style.transition = "1s";
    let secondRow = document.getElementById("pageRectanglesRow");
    secondRow.style.height = "90%";
  }
}
