<style>
    @import url('https://fonts.googleapis.com/css?family=Armata');
  </style>
  <div class="bg" style="background-image: url('assets/happyfamily2.jpg'); background-position:top;" (wheel)="onWindowScroll()">
    <div id="pageTopRectanglesRow">
      <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/occasional-offers' | localize" routerLinkActive="active">{{"backSecond" | transloco}}</div>
      <div class="pageTopMarginBox">
      </div>
      <div class="centered pageMarginBox BoxFadeInFromRight">
        <app-language-selector></app-language-selector>
      </div>
    </div>
    <div id="pageRectanglesRow">
      <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
      <div id="content" class="pageContentBox">
        <h1 class="topic" style="color:#00c9ff;">{{"packages.summer" | transloco}}</h1>
        <br/>
       <h3 class="heading">{{"packageIncludes" | transloco}}</h3>
        <div class="infoBox">
          <ul>
              <li>
                  <span class="title">{{"accommodation" | transloco}}</span>
                  <span class="text">7x {{"accommodationText" | transloco}}</span>
                </li>
            <li>
              <span class="title">{{"alimentation" | transloco}}</span>
              <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"sauna" | transloco}}</span>
              <span class="text">{{"unlimitedSauna" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"aquapark" | transloco}}</span>
              <span class="text">{{"aquaparkAccessOneHour" | transloco}}</span>
            </li>
            <li>
                <span class="title">{{"forKids" | transloco}}</span>
                <span class="text">{{"playgroundInGarden" | transloco}}</span>
              </li>
              <li>
                  <span class="title">{{"forKids" | transloco}}</span>
                  <span class="text">{{"playRoom" | transloco}}</span>
                </li>
                <li>
                  <span class="title">{{"animations" | transloco}}</span>
                  <span class="text">1x {{"animationsForChilds" | transloco}}</span>
                </li>
          </ul>
        </div>
        <app-we-provide></app-we-provide>
        <app-attractions></app-attractions>
        <h3 class="heading">{{"singlePrices" | transloco}}</h3>
        <div style="overflow-x:auto;">
          <table class="priceTableSmallText" style="font-size: 18px; width: 100%;" cellspacing="0" cellpadding="0">
              <tbody>
              <tr>
              <td> {{"standardRoom" | transloco}}**</td>

              <td> 2060 zł</td>
              </tr>
              <tr>
                <td> {{"addictionalDay" | transloco}}**</td>

                <td> 290 zł</td>
              </tr>
              <tr>
              <td> {{"extraBed" | transloco}}</td>

              <td> 1620 zł</td>
              </tr>
              <tr>
              <td> {{"kidsUnderFour" | transloco}}</td>

              <td> {{"free" | transloco}}*</td>
              </tr>
              <tr>
              <td> {{"kid" | transloco}}<br></td>

              <td> -50%*</td>
              </tr>
              <tr>
              <td> {{"seaView" | transloco}}</td>
              <td> 40 zł</td>
              </tr>
              <tr>
              <td>  {{"parking" | transloco}} - {{"day" | transloco}}</td>
              <td> 35 zł</td>
              </tr>
              <tr>
              <td> {{"bedForChild" | transloco}}</td>
              <td> 35 zł</td>
              </tr>
              </tbody>
              </table>
              
      </div>
        <br>
        <span style="font-size: 20px;"> {{"from" | transloco}} 25.06.2022 {{"to" | transloco}} 31.08.2022</span>
        <br/>
        <span style="font-size: 20px;"> *{{"withTwoFullyPaid" | transloco}}</span>
        <br/>
        <span style="font-size: 20px;"> **{{"pricePerOnePerson" | transloco}}</span>
        <h3 class="heading">{{"additionalFees" | transloco}}</h3>
        <div class="infoBox">
          <ul>
            <li>
              <span class="title">{{"touristTax" | transloco}}</span>
              <span class="text">2,50 zł {{"day" | transloco}}/{{"person" | transloco}}</span>
            </li>
          </ul>
        </div>
        <additional-informations></additional-informations>
      </div>
      <div class="pageSideMarginBox BoxFadeInFromRight"></div>
    </div>
  </div>