import { Component, OnInit, HostListener } from '@angular/core';
import { NavBarService } from '../navbar.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LanguageSelectorComponent } from 'app/shared/components/language-selector/language-selector.component';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';

@Component({
  standalone: true,
  imports: [TranslocoModule, CommonModule, LanguageSelectorComponent, LocalizeRouterModule, RouterModule],
  selector: 'app-spa',
  templateUrl: './spa.component.html',
  styleUrls: ['./spa.component.css']
})
export class SpaComponent {


  constructor(
    public navBarService: NavBarService,
    public translate: TranslocoService) {
    this.navBarService.hide()
  }

  onWindowScroll() {
    let firstRow = document.getElementById("pageTopRectanglesRow")
    firstRow.style.height = "10%";
    firstRow.style.transition = "1s";
    let secondRow = document.getElementById("pageRectanglesRow")
    secondRow.style.height = "90%"
  }
}
