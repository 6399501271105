<div class="bg" (wheel)="onWindowScroll()">
    <div id="pageTopRectanglesRow">
      <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/' | localize" routerLinkActive="active">{{"backSecond" | transloco }}</div>
      <div class="pageTopMarginBox">
      </div>
      <div class="pageMarginBox BoxFadeInFromRight"></div>
    </div>
    <div id="pageRectanglesRow">
      <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
      <div id="content" class="pageContentBox" style="padding:0!important;">
          <iframe src="https://www.google.com/maps/embed?pb=!4v1534129474590!6m8!1m7!1sCAoSLEFGMVFpcFB4dnkwNElXaWlxVGQ4c3FDaDNvUUJuR2JIbEYya1cwaGNsSExj!2m2!1d54.219211453782!2d15.769380698897!3f281.5!4f-0.4000000000000057!5f0.5970117501821992" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen></iframe>
      </div>
      <div class="pageSideMarginBox BoxFadeInFromRight"></div>
    </div>
  </div>