import { Injectable, inject } from "@angular/core"
import { ActivatedRoute, NavigationEnd, Resolve, Router } from "@angular/router"
import { AppLanugagesShort } from "../data/app.data";
import { Observable, filter, of, take } from "rxjs";
import { LocalizeRouterService } from "@penleychan/ngx-transloco-router";

@Injectable({
    providedIn: 'root',
})
export class LanguageResolver implements Resolve<void> {

    router = inject(Router)
    localize = inject(LocalizeRouterService)

    resolve(): Observable<any> | Promise<any> {
        return this.checkLanguageAndRedirect();
    }

    checkLanguageAndRedirect() {

        const validLanguages = AppLanugagesShort;

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                const path = event.urlAfterRedirects || event.url;
                const urlParts = path.split('/');
                const language = urlParts[1];
                
                if (!validLanguages.includes(language)) {
                    const translatedPath = this.localize.translateRoute(path.toString())
                    this.router.navigate([translatedPath]);
                }
            });

        return of(true)

    }
}