import { Injectable } from '@angular/core';
import { BehaviorSubject } from '../../node_modules/rxjs';


@Injectable({
    providedIn: 'root'
})
export class NavBarService {

private navBarSubject = new BehaviorSubject<boolean>(false);

loaderState = this.navBarSubject.asObservable();

constructor() { }

show() {
        this.navBarSubject.next(true);
    }

hide() {
        this.navBarSubject.next(false);
    }
}