import { Component, OnInit } from '@angular/core';
import { NavBarService } from '../navbar.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LanguageSelectorComponent } from 'app/shared/components/language-selector/language-selector.component';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';

@Component({
standalone: true,
imports: [TranslocoModule, CommonModule, LanguageSelectorComponent, LocalizeRouterModule, RouterModule],
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  showNav = false;
  constructor(
    
    public translate:TranslocoService,
    public navBarService:NavBarService
  
  ) {
    const data = this.translate.translate('filesToDownload')
    document.title = "Borgata.pl - " + data

    
    this.navBarService.hide()
   }
   
  ngOnInit() { 

    const data = this.translate.translate('filesToDownload')
    document.title = "Borgata.pl - " + data
   
  }

  onWindowScroll() {
  let firstRow = document.getElementById("pageTopRectanglesRow")
  firstRow.style.height = "10%";
  firstRow.style.transition = "1s";
  let secondRow = document.getElementById("pageRectanglesRow")
  secondRow.style.height = "90%"
  }

}
