import { Injectable } from '@angular/core';
import { HttpClient,  } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class DataService{
  
  private vouchers = new BehaviorSubject<any>([]);
  currentVouchers = this.vouchers.asObservable();


  private getWeatherUrl = "http://api.openweathermap.org/data/2.5/weather?q=Ustronie%20Morskie&appid=8f4378c75a249308884d4583c259dc19&units=metric";
  private host = "https://api.voucherries.com";
  private getVouchersUrl = this.host+"/External/categories/b6afaa4a-5cf9-4f38-a1c9-4d4ef60e4108"
  constructor(private httpClient:HttpClient) {
  }

  processError(err) {
    console.log(err.message," ERROR");
  }
  
  getWeather() {
    return this.httpClient.get(this.getWeatherUrl)
  }

  getVouchers() {
    this.httpClient
      .get<any>(this.getVouchersUrl)
      .subscribe({
        next: (data)=> {
          this.vouchers.next(data);
        },
        error: (err)=> {
          this.processError(err)
        }
    });
  }
}
