import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import {MatTooltipModule} from '@angular/material/tooltip';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';

@Component({
  standalone: true,
  imports: [TranslocoModule, CommonModule, MatTooltipModule],
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html'
})
export class LanguageSelectorComponent implements OnInit {

  selected;
  showLanguages = false;

  languages: any = [
    {
      short: "pl",
      language: "polski",
      imgSM: "assets/images/pl.png",
    },
    {
      short: "de",
      language: "Deutsche",
      imgSM: "assets/images/de.png",
    },
    {
      short: "en",
      language: "english",
      imgSM: "assets/images/gb.png",
    }
  ];
  
  @Output() languageChangedEvent = new EventEmitter<string>();
 

  constructor(
    private translate: TranslocoService,
    private _localize: LocalizeRouterService
  )
  {
    this.selected = this.languages.find(v => v.short === this.translate.getActiveLang());
  }

  ngOnInit(): void {
  //   this.localize.routerEvents.subscribe((language: string) => {
  //     this.translate.use(language)
  //     this.selected = this.languages.find(v => v.short === language);
  // });
  }
  changeLanguage(lang)
  {
    this.translate.setActiveLang(lang)
    this._localize.changeLanguage(lang)
    // this.localize.changeLanguage(lang)
    this.selected = this.languages.find(v => v.short === lang);
    this.showLanguages = false;
  }

}
