<div class="bgOrganisationOfEvents" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/menu' | localize" routerLinkActive="active">
      {{"backSecond" | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div class="pageContentBox" style="font-size: 18px; padding: 0px 0px 0px 0px !important;">

      <div style="padding: 20px;">
        <h2>{{"organisation-of-events" | transloco}}</h2>
        <p class="h4" style="text-align: justify; line-height: 26px;">{{"organisation-of-event-content" | transloco}} <a [routerLink]="'/contact' | localize"> {{"contact-with-reception" | transloco }}</a>.</p>
      </div>
      
      <div class="imageRow" [routerLink]="'/organisation-of-events/fish-tasting' | localize" routerLinkActive="active">
        <div class="background" style="background-image: url('assets/fish-tasting.jpg')">
          <div class="textCenter">
            {{"fish-tasting" | transloco}} 2021
          </div>
        </div>
      </div>      
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>