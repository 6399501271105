<style>
  @import url('https://fonts.googleapis.com/css?family=Armata');
</style>
<div class="bg" style="background-image: url('/assets/happyfamily.jpg')" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/packets' | localize" routerLinkActive="active">{{"backSecond" | transloco}}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h3 class="topic" style="color:#802400;">{{"packages.sevendays" | transloco}} 2024</h3>
      <br/>
     <h3 class="heading">{{"packageIncludes" | transloco}}</h3>
      <div class="infoBox">
        <ul>
            <li>
                <span class="title">{{"accommodation" | transloco}}</span>
                <span class="text">7x {{"accommodationText" | transloco}}</span>
              </li>
          <li>
            <span class="title">{{"alimentation" | transloco}}</span>
            <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sauna" | transloco}}</span>
            <span class="text">{{"unlimitedSauna" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"aquapark" | transloco}}</span>
            <span class="text">{{"aquaparkAccess" | transloco}}</span>
          </li>
          <li>
            <span class="text">{{"aquaparkAccessLimit" | transloco}}</span>
          </li>
        </ul>
      </div>
      <app-we-provide></app-we-provide>
      <app-attractions></app-attractions>
      <h3 class="heading">{{"singlePrices" | transloco}}</h3>
      <div style="overflow-x:auto;">
        <table class="priceTableSmallText" style="font-size: 18px; width: 100%;" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <th></th>
              <th>{{"season" | transloco}} A<br><br>02.01 - 31.03<br>01.11 - 22.12</th>
              <th>{{"season" | transloco}} B<br><br>01.04 - 30.04<br>01.10 - 31.10</th>
              <th>{{"season" | transloco}} C<br><br>01.05 - 30.06<br>01.09 - 30.09</th>
              <th>{{"season" | transloco}} D<br><br>01.07 - 31.08</th>
            </tr>

            <tr>
              <td>
                <b>{{"double-room" | transloco}}</b>
                <br>
                / {{"pricePerOnePerson" | transloco}} {{"with-breadfast-and-evening-meal" | transloco}} /
              </td>
              <td> 1390 zł</td>
              <td> 1669 zł</td>
              <td> 2029 zł</td>
              <td> 2469 zł</td>
            </tr>
            <tr>
              <td>
                <b>{{"double-room" | transloco}}</b> <br>
                / {{"pricePerOnePerson" | transloco}} {{"with-breadfast" | transloco}} /</td>
              <td> 910 zł</td>
              <td> 1190 zł</td>
              <td> 1619 zł</td>
              <td> 2049 zł</td>
            </tr>
            <tr>
              <td> {{"kidsUnderFour" | transloco}}
                <br/>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
            </tr>
            <tr>
              <td> {{"kids-between-5-12" | transloco}}<br>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> 50%</td>
              <td> 50%</td>
              <td> 50%</td>
              <td> 50%</td>
            </tr>
            <tr>
              <td> {{"kids-over-13" | transloco}}<br>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> 80%</td>
              <td> 80%</td>
              <td> 80%</td>
              <td> 80%</td>
            </tr>
            <tr>
              <td> {{"seaView" | transloco}} <br/>
              / {{"day" | transloco}} /
              </td>
              <td>20 zł</td>
              <td>30 zł</td>
              <td>40 zł</td>
              <td>60 zł</td>
            </tr>
            <tr>
              <td> {{"parking" | transloco}} <br/>
              / {{"day" | transloco}} /
              </td>
              <td> 20 zł </td>
              <td> 30 zł </td>
              <td> 30 zł </td>
              <td> 40 zł</td>
            </tr>
            <tr>
              <td> {{"bedForChild" | transloco}}</td>
              <td> 30 zł</td>
              <td> 40 zł</td>
              <td> 40 zł</td>
              <td> 50 zł</td>
            </tr>
           
          </tbody>
        </table>
      </div>
      <br>
      <span style="font-size: 20px;"> *{{"withTwoFullyPaid" | transloco}}</span>
      <br/>
      <span style="font-size: 20px;"> **{{"pricePerOnePerson" | transloco}}</span>
      <h3 class="heading">{{"additionalFees" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"touristTax" | transloco}}</span>
            <span class="text">2,50 zł {{"day" | transloco}}/{{"person" | transloco}}</span>
          </li>
        </ul>
      </div>
      <additional-informations></additional-informations>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>