<div class="bg" style="background-image: url('assets/cosmeticalTreatment.jpg')" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/spa' | localize" routerLinkActive="active">{{"backSecond"
      | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h1>
        {{"cosmeticalTreatments" | transloco}}
      </h1>
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <div style="overflow-x:auto;"> 
            <table style="width:100%;">
            <tbody>
              <tr style="font-weight: 900;">
                <td>
                  {{"treatment" | transloco}}
                </td>
                <td>
                  {{"duration" | transloco}}
                </td>
                <td>
                  {{"priceIn" | transloco}} PLN
                </td>
              </tr>
              <tr style="height:20px;">
                <td colspan="3"></td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.instantRejuvenatingTreatment" | transloco}}</td>
                <td> 40-50 min</td>
                <td> 160 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.moisturizingTreatmentWithPumpkinExtract" | transloco}}</td>
                <td> 40-50 min</td>
                <td> 150 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.instantTighteningAndBrighteningTreatment" | transloco}}</td>
                <td> 40-50 min</td>
                <td> 170 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.enzymeFacialPeeling" | transloco}}</td>
                <td> 20 min</td>
                <td> 50 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.algaeMaskWithFaceMassage" | transloco}}</td>
                <td> 40-50 min</td>
                <td> 130 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.algaeMask" | transloco}}</td>
                <td> 30 min</td>
                <td> 85 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.faceMassage" | transloco}}</td>
                <td> 20 min</td>
                <td> 70 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.spaRitualOfSolace" | transloco}}</td>
                <td> 75 min</td>
                <td style="text-align: left;"> 170 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.sosTreatment" | transloco}}</td>
                <td> 60 min</td>
                <td style="text-align: left;"> 210 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.spaRitualRelaxation" | transloco}}</td>
                <td> 75 min</td>
                <td style="text-align: left;"> 180 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.relaxingMassage" | transloco}}</td>
                <td> 75 min</td>
                <td style="text-align: left;"> 190 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.aromaticBodyScrub" | transloco}}</td>
                <td> 30 min</td>
                <td style="text-align: left;"> 90 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.aromaticBodyScrubPlusShe" | transloco}}</td>
                <td> 75 min</td>
                <td style="text-align: left;"> 200 zł</td>
              </tr>
              <tr>
                <td> {{"cosmeticalTreatment.bodyPeeling" | transloco}}</td>
                <td> 40 min</td>
                <td style="text-align: left;"> 120 zł</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <div style="height:10px;"></div>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>