import { HttpClient, HttpClientModule } from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { appRoutes } from './app.routes';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject } from '@angular/core';
import { provideRouter, withPreloading, PreloadAllModules, withInMemoryScrolling } from "@angular/router";
import { provideTransloco, TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from "rxjs";
import { LOCALIZE_ROUTER_CONFIG, localizeRouterConfig, LocalizeRouterModule } from "@penleychan/ngx-transloco-router";
import { AppLanugagesShort } from "./shared/data/app.data";
import { TranslocoHttpLoader } from "./shared/loaders/transloco-http.loader";

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        importProvidersFrom(HttpClientModule),
       
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
        ),
        importProvidersFrom(LocalizeRouterModule.forRoot(appRoutes)),
        {
            provide: LOCALIZE_ROUTER_CONFIG,
            useValue: localizeRouterConfig({
              translateRoute: false,
            })
        },
        // Transloco Config
        provideTransloco({
            config: {
                availableLangs      : AppLanugagesShort,
                defaultLang         : 'pl',
                fallbackLang        : 'en',
                reRenderOnLangChange: true,
                prodMode            : true,

            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            useFactory: () =>
            {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi     : true,
        }

        
    ]
}

