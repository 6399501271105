
<div class="bgWinterHolidays" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/occasional-offers' | localize" routerLinkActive="active">{{"backSecond" | transloco}}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h1 class="topic" style="color:#80006b;">{{"packages.winterHolidays" | transloco}} 2024</h1>
      <br/>
      <h3 class="heading">{{"packageIncludes" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"alimentation" | transloco}}</span>
            <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"massage" | transloco}}</span>
            <span class="text">{{"segmentalMassageOrFaceMassage" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"discount" | transloco}}</span>
            <span class="text">{{"discountTenForSPA" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"animations" | transloco}}</span>
            <span class="text">{{"animationsForChilds" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"bonfire" | transloco}}</span>
            <span class="text">{{"bonfireTextWithoutBeer" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sauna" | transloco}}</span>
            <span class="text">{{"unlimitedSauna" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"aquapark" | transloco}}</span>
            <span class="text">{{"aquaparkAccess" | transloco}}</span>
          </li>
        </ul>
      </div>
      
     <app-we-provide-winter></app-we-provide-winter>
      <app-attractions></app-attractions>
      <h3 class="heading">{{"pricePerPerson" | transloco}}</h3>
      <table class="priceTable table table-striped" >
        <tbody>
          <tr>
            <td style="text-align: left;">&nbsp;</td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 02.01 {{"to" | transloco}} 28.02.2024
              </p>
            </td>
          </tr>
          <tr>
            <td class="topicTd" style="text-align: center;">
                5 {{"wordDays" | transloco}}
            </td>
            <td style="text-align: center;">
              <strong>799zł</strong>
            </td>
          </tr>
          <tr>
          <tr>
            <td class="topicTd" style="text-align: center;">
                6 {{"wordDays" | transloco}}
            </td>
            <td style="text-align: center;">
              <strong>999zł</strong>
            </td>
          </tr>
          <tr>
            <td class="topicTd" style="text-align: center;">
                7 {{"wordDays" | transloco}}
            </td>
            <td style="text-align: center;">
              <strong>1199zł</strong>
            </td>
          </tr>
        </tbody>
      </table>
      
      <h3 class="heading">{{"additionalFees" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
              <span class="title">{{"kidsUnderSix" | transloco}}</span>
              <span class="text">{{"free" | transloco}}</span>
          </li>
          <li>
              <span class="title">{{"kidsBetweenSevenAndFourteen" | transloco}}</span>
              <span class="text">-50%</span>
          </li>
          <li>
              <span class="title">{{"addictionalDay" | transloco}}</span>
              <span class="text">199,00 zł</span>
            </li>
            <li>
              <span class="title">{{"parking" | transloco}}</span>
              <span class="text">
                <!-- {{"from" | transloco}} -->
                20 zł 
                <!-- {{"to" | transloco}} 35 zł {{"day" | transloco}} <br/> {{"depends-on-season" | transloco}} -->
              </span>
            </li>
          <li>
            <span class="title">{{"touristTax" | transloco}}</span>
            <span class="text">2,50 zł {{"day" | transloco}}/{{"person" | transloco}}</span>
          </li>
        </ul>
      </div>
      <additional-informations></additional-informations>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>