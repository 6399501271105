import { Component, OnInit } from '@angular/core';
import { NavBarService } from '../navbar.service';
import { CommonModule } from '@angular/common';
import { Translation, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [TranslocoModule, CommonModule, RouterModule, LocalizeRouterModule],
  selector: 'app-rectangles',
  templateUrl: './rectangles.component.html',
  styleUrls: ['./rectangles.component.scss']
})
export class RectanglesComponent {

  constructor(
    public navBarService:NavBarService,
    public translate:TranslocoService
    ) { 
      this.navBarService.show()
    }

  raisingEffect(item){
    let newsText = item+"Text"
    let selected = document.getElementById(newsText)
    selected.style.visibility="visible"
    selected.style.opacity = "0"
    selected.style.animation="FadeInFromDown .2s ease forwards .2s";
    selected.style.webkitAnimation="-webkit-animation: FadeInFromDown .2s ease forwards .2s"


  }
  hideEffect(item){
    let newsText = item+"Text"
    let selected = document.getElementById(newsText)
    selected.style.opacity = "0"
    selected.style.visibility="hidden"
    selected.style.animation="FadeOutFromDown .2s ease forwards .2s";
    selected.style.webkitAnimation="-webkit-animation: FadeOutFromDown .2s ease forwards .2s"
  }

}
