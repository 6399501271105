import { Component, Input, OnInit } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
standalone: true,
imports: [TranslocoModule, CommonModule],
  selector: 'infobox-list-item',
  templateUrl: './infobox-list-item.component.html',
  styleUrls: ['./infobox-list-item.component.css']
})
export class InfoboxListItemComponent implements OnInit {

  @Input('title') title;
  @Input('text') text;

  constructor() { }

  ngOnInit() {
  }

}
