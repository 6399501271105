<style>
  @import url('https://fonts.googleapis.com/css?family=Armata');
</style>
<div class="bg" style="background-image: url('/assets/happyfamily2.jpg')" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/packets' | localize" routerLinkActive="active">{{"backSecond" | transloco}}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h3 class="topic" style="color:#800080;">{{"packages.health" | transloco}} 2024</h3>

      <br/>
      <h3 class="heading"> {{"packageIncludes" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"alimentation" | transloco}}</span>
            <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"treatments" | transloco}}</span>
            <span class="text">{{"threeAnyMedicalTreatments" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sauna" | transloco}}</span>
            <span class="text">{{"unlimitedSauna" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"aquapark" | transloco}}</span>
            <span class="text">{{"aquaparkAccess" | transloco}}</span>
          </li>
          <li>
            <span class="text">{{"aquaparkAccessLimit" | transloco}}</span>
          </li>
        </ul>
      </div>
      <app-we-provide></app-we-provide>
      <app-attractions></app-attractions>
      <h3 class="heading">{{"pricePerPerson" | transloco}}</h3>
      <div style="overflow-x:auto;">
      <table class="priceTable table table-striped" >
        <tbody>
          <tr>
            <td style="text-align: left;">&nbsp;</td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 02.01 {{"to" | transloco}} 31.03
              </p>
              <p>
                  {{"from" | transloco}} 01.11 {{"to" | transloco}} 22.12
              </p>
            </td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 01.04 {{"to" | transloco}} 30.04 
              </p>
              <p>
                  {{"from" | transloco}} 01.10 {{"to" | transloco}} 31.10
              </p>
            </td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 01.05 {{"to" | transloco}} 30.06 
              </p>
              <p>
                  {{"from" | transloco}} 01.09 {{"to" | transloco}} 31.10
              </p>
            </td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 01.07 {{"to" | transloco}} 31.08
              </p>
            </td>
          </tr>
          <tr>
            <td class="topicTd" style="text-align: center;">
              <b>5 {{"nights" | transloco}}</b>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1199zł</strong><br/>
              <strong>Bethel: 1129zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1289zł</strong><br/>
              <strong>Bethel: 1209zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1679zł</strong><br/>
              <strong>Bethel: 1599zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1999zł</strong><br/>
              <strong>Bethel: 1899zł</strong>
            </td>
          </tr>
          <tr>
            <td class="topicTd">
              <b>6 {{"nights" | transloco}}</b>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1419zł</strong><br/>
              <strong>Bethel: 1339zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1539zł</strong><br/>
              <strong>Bethel: 1439zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1989zł</strong><br/>
              <strong>Bethel: 1909zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 2359zł</strong><br/>
              <strong>Bethel: 2269zł</strong>
            </td>
          </tr>
          <tr>
            <td class="topicTd">
              <b>7 {{"nights" | transloco}}</b>
              
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1649zł</strong><br/>
              <strong>Bethel: 1539zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 1769zł</strong><br/>
              <strong>Bethel: 1669zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 2309zł</strong><br/>
              <strong>Bethel: 2219zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>Borgata: 2769zł</strong><br/>
              <strong>Bethel: 2669zł</strong>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      
      <!-- <h3 class="heading"> {{"pricePerTwoPerson" | transloco}}</h3>
      <table class="priceTable table table-striped">
        <tbody>
          <tr>
            <td style="text-align: center;">&nbsp;</td>
            <td style="text-align: center;">
                <p>
                    {{"from" | transloco}} 02.01 {{"to" | transloco}} 28.04
                </p>
                <p>
                    {{"from" | transloco}} 01.11 {{"to" | transloco}} 22.12
                </p>
            </td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 28.04 {{"to" | transloco}} 31.05 
              </p>
              <p>
                  {{"from" | transloco}} 01.10 {{"to" | transloco}} 31.10
              </p>
            </td>
          </tr>
          <tr>
            <td class="topicTd">
              5 {{"nights" | transloco}}
            </td>
            <td style="text-align: center;">
              <strong>1369zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>1950zł</strong>
            </td>
          </tr>
          <tr>
            <td class="topicTd">
              6 {{"nights" | transloco}}
            </td>
            <td style="text-align: center;">
              <strong>1559zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>2304zł</strong>
            </td>
          </tr>
          <tr>
            <td class="topicTd">
              7 {{"nights" | transloco}}
            </td>
            <td style="text-align: center;">
              <strong>1759zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>2660zł</strong>
            </td>
          </tr>
        </tbody>
      </table> -->
      
      <h3 class="heading">{{"additionalFees" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"parking" | transloco}}</span>
            <span class="text">{{"from" | transloco}} 20 zł {{"to" | transloco}} 40 zł {{"day" | transloco}} <br/> {{"depends-on-season" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"touristTax" | transloco}}</span>
            <span class="text">2,50 zł {{"day" | transloco}}/{{"person" | transloco}}</span>
          </li>
        </ul>
      </div>
      <additional-informations></additional-informations>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>