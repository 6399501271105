<div class="bgVouchers" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div
      id="backButton"
      class="pageMarginBox BoxFadeInFromLeft"
      [routerLink]="'/menu' | localize"
    >
      {{ "backSecond" | transloco }}
    </div>
    <div class="pageTopMarginBox">

    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    
    <div class="pageContentBox" style="font-size: 18px; line-height:2.7rem; padding:0; ">
      <div style="width: 100%; height: 350px; background-image: url('/assets/vouchers.jpeg');background-size: cover;text-align: center;">
          <div style="color:white;padding-top:150px;font-size: 35px;">{{ "vouchers" | transloco }}</div>
          
          <a href="https://voucherries.com/borgata" target="_blank" style="text-decoration:none;">
            <div class="buyVoucherBtn"> {{"buyVoucher" | transloco}}</div>
          </a>
          
      </div>
      <!-- <div class="row" style="text-align: center;">
        <h1>{{ "vouchers" | transloco }}</h1>
      </div> -->
      <div class="row row-content" >
        <p class="h2">{{ "voucherTextFirst" | transloco }}</p>
      </div>
      <br />
      <div class="row row-content">
        <p class="h2">{{ "voucherTextSecond" | transloco }}</p>
      </div>

      <div class="row row-content">
        <p class="h2">{{ "voucherTextThird" | transloco }}</p>
      </div>

      <div class="row row-content" >
        <p class="h2">
          {{ "voucherTextFourth" | transloco }}
          <a
            style="text-decoration: none; color:rgb(0, 183, 255)"
            href="https://voucherries.com"
            >VouCherries</a
          >, {{ "voucherTextFifth" | transloco }}
          <a
            style="text-decoration: none; color:rgb(0, 183, 255)"
            href="https://voucherries.com/rules"
            >{{ "withRules" | transloco }}</a
          >
          {{ "service" | transloco }}.
        </p>
      </div>
      <div class="row row-content" style="text-align: justify;">
        <p class="h2">{{ "moreAt" | transloco }} <a href="http://voucherries.com/knowledge-base">www.voucherries.com/knowledge-base</a></p> 
       
      </div>
      <div class="row row-content" >
        <p class="h2">{{ "availableVouchers" | transloco }}:</p>
        </div>

      <div *ngIf="categories == null" style="text-align: center; font-size:28px;">
          <p>...{{ "loading" | transloco }}...</p>
      </div>

      <a target="_blank" href="https://voucherries.com/borgata">
        
      <div class="row" *ngFor="let c of categories" style="padding-left:5px; padding-right:5px;">
        
        <div class="col-xs-12" style="padding-left: 0;padding-right:0;">
          <div class="imageRow">
            <div
              class="background"
              style="background-color:#2d2d2d;
            cursor: default;"
            >
              <div class="textCenter">
                {{ "category" | transloco }} - {{ c.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" *ngFor="let t of c.templates" style="width:100%;padding-left: 0;padding-right:0;">
          <div [ngClass]="t.background">
            
            <figure
              class="snip0034"
              style=" display: flex;
              justify-content: center;
              align-items: center;"
            >
            <span class="sale-sign">-{{calculateSale(t.value,t.normalBuyValue)}}%</span>
              <figcaption>
                <div class="left">
                  <h3>{{ "buyFor" | transloco }}</h3>
                </div>
                <div class="right">
                  <h3>{{ t.normalBuyValue }} PLN</h3>
                </div>
              </figcaption>
              <div class="center"><i class="fa fa-shopping-basket"></i></div>
              <span class="voucherName">{{ t.name }}</span>
            </figure>
            <div style="clear: both;"></div>
          </div>
        </div>
      </div>
      </a>
      <div style="height: 200px;"></div>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>
