<style>
  @import url('https://fonts.googleapis.com/css?family=Armata');
</style>
<div class="bg" style="background-image: url('/assets/spa2.jpg')"(wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/packets' | localize" routerLinkActive="active">{{"backSecond" | transloco}}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h3 class="topic" style="color:#80006b;">{{"packages.mediKur" | transloco}} 2024</h3>
      <br/>
      <h3 class="heading">{{"packageIncludes" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"alimentation" | transloco}}</span>
            <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"consultation" | transloco}}</span>
            <span class="text">{{"consultationWithPhysiotherapist" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"treatments" | transloco}}</span>
            <span class="text">{{"nineAnyMedicalTreatments" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"massages" | transloco}}</span>
            <span class="text">{{"segmentalMassage" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sauna" | transloco}}</span>
            <span class="text">{{"unlimitedSauna" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"aquapark" | transloco}}</span>
            <span class="text">{{"aquaparkAccess" | transloco}}</span>
          </li>
          <li>
            <span class="text">{{"aquaparkAccessLimit" | transloco}}</span>
          </li>
        </ul>
      </div>
      <app-we-provide></app-we-provide>
      <app-attractions></app-attractions>
      <h3 class="heading">{{"pricePerPerson" | transloco}}</h3>
      <div style="overflow-x:auto;">
        <table class="priceTable table table-striped priceTableSmallText" >
          <tbody>
            <tr>
              <td style="text-align: left;">&nbsp;</td>
              <td style="text-align: center;">
                <p>
                    {{"from" | transloco}} 02.01 {{"to" | transloco}} 31.03
                </p>
                <p>
                    {{"from" | transloco}} 01.11 {{"to" | transloco}} 22.12
                </p>
              </td>
              <td style="text-align: center;">
                <p>
                    {{"from" | transloco}} 01.04 {{"to" | transloco}} 31.05 
                </p>
                <p>
                    {{"from" | transloco}} 01.10 {{"to" | transloco}} 31.10
                </p>
              </td>
              <td style="text-align: center;">
                <p>
                    {{"from" | transloco}} 01.06 {{"to" | transloco}} 31.06 
                </p>
                <p>
                    {{"from" | transloco}} 01.09 {{"to" | transloco}} 30.09
                </p>
              </td>
             
              <td style="text-align: center;">
                <p>
                    {{"from" | transloco}} 01.07 {{"to" | transloco}} 31.08
                </p>
              </td>
            </tr>
            <tr>
              <td class="topicTd" style="text-align: center;">
                <b>7 {{"nights" | transloco}}</b>
                
              </td>
              <td style="text-align: center;">
                <strong>Borgata: 1579zł</strong><br/>
                <strong>Bethel: 1459zł</strong>
              </td>
              <td style="text-align: center;">
                <strong>Borgata: 1799zł</strong><br/>
                <strong>Bethel: 1659zł</strong>
              </td>
              <td style="text-align: center;">
                <strong>Borgata: 2239zł</strong><br/>
                <strong>Bethel: 2169zł</strong>
              </td>
              <td style="text-align: center;">
                <strong>Borgata: 2869zł</strong><br/>
                <strong>Bethel: 2739zł</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     
      
      <h3 class="heading">{{"additionalFees" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"parking" | transloco}}</span>
            <span class="text">{{"from" | transloco}} 20 zł {{"to" | transloco}} 40 zł {{"day" | transloco}} <br/> {{"depends-on-season" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"touristTax" | transloco}}</span>
            <span class="text">2,50 zł {{"day" | transloco}}/{{"person" | transloco}}</span>
          </li>
        </ul>
      </div>
      <additional-informations></additional-informations>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>