<div class="bgSpa" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/menu' | localize" routerLinkActive="active">{{"backSecond" | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div class="pageContentBox" style="font-size: 18px;">
      <div class="row">
      <h2>
        {{"spaMainText" | transloco}}
      </h2>
      </div>
      <br>
      <div class="row">
        Sauna: 14:00 - 21:00
        <br>
        {{"beforeSauna" | transloco}}
      </div>
      <br>
  
      <div class="imageRow" [routerLink]="'massages' | localize" routerLinkActive="active">
          <div class="background" style="background-image: url('assets/spa1.jpg')">
            <div class="textCenter">
                {{"massages" | transloco}}
            </div>
          </div>
        </div>
        <div class="imageRow" [routerLink]="'physical-treatments' | localize" routerLinkActive="active">
          <div class="background" style="background-image: url('assets/spa2.jpg')">
            <div class="textCenter">
                {{"physicalTreatments" | transloco}}
            </div>
          </div>
        </div>
        <div class="imageRow" [routerLink]="'cosmetical-treatments' | localize" routerLinkActive="active">
          <div class="background" style="background-image: url('assets/cosmeticaltreatment.jpg')">
            <div class="textCenter">
                {{"cosmeticalTreatments" | transloco}}
            </div>
          </div>
        </div>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>