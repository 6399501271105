<style>
  @import url('https://fonts.googleapis.com/css?family=Armata');
</style>
<div class="bgReception" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/menu' | localize" routerLinkActive="active">
      {{"backSecond" | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h3 class="topic" style="color:black;">{{"prices" | transloco}} 2024</h3>
      <hr />
      <div style="overflow-x:auto;">
        <table class="priceTableSmallText" style="font-size: 18px; width: 100%;" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <th></th>
              <th>{{"season" | transloco}} A<br><br>02.01 - 31.03<br>01.11 - 22.12</th>
              <th>{{"season" | transloco}} B<br><br>01.04 - 30.04<br>01.10 - 31.10</th>
              <th>{{"season" | transloco}} C<br><br>01.05 - 30.06<br>01.09 - 30.09</th>
              <th>{{"season" | transloco}} D<br><br>01.07 - 31.08</th>
            </tr>

            <tr>
              <td>
                {{"double-room" | transloco}}<br>
                / {{"pricePerOnePerson" | transloco}} /
              </td>
              <td> 160 zł</td>
              <td> 180 zł</td>
              <td> 250 zł</td>
              <td> 320 zł</td>
            </tr>
            <tr>
              <td> {{"double-room" | transloco}}<br>
                / {{"for-single-use" | transloco}} /</td>
              <td> + 40%</td>
              <td> + 40%</td>
              <td> + 40%</td>
              <td> + 40%</td>
            </tr>
            <tr>
              <td> {{"extraBed" | transloco}}</td>
              <td> 80%</td>
              <td> 80%</td>
              <td> 80%</td>
              <td> 80%</td>
            </tr>
            <tr>
              <td> {{"kidsUnderFour" | transloco}}
                <br/>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
            </tr>
            <tr>
              <td> {{"kids-between-4-12" | transloco}}<br>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> 50%</td>
              <td> 50%</td>
              <td> 50%</td>
              <td> 50%</td>
            </tr>
            <tr>
              <td> {{"kids-over-12" | transloco}}<br>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> 80%</td>
              <td> 80%</td>
              <td> 80%</td>
              <td> 80%</td>
            </tr>
            <tr>
              <td> {{"seaView" | transloco}} <br/>
              / {{"day" | transloco}} /
              </td>
              <td>30 zł</td>
              <td>40 zł</td>
              <td>40 zł</td>
              <td>50 zł</td>
            </tr>
            <tr>
              <td> {{"parking" | transloco}} <br/>
              / {{"day" | transloco}} /
              </td>
              <td> 20 zł </td>
              <td> 30 zł </td>
              <td> 30 zł </td>
              <td> 40 zł</td>
            </tr>
            <tr>
              <td> {{"bedForChild" | transloco}}</td>
              <td> 30 zł</td>
              <td> 40 zł</td>
              <td> 40 zł</td>
              <td> 40 zł</td>
            </tr>
            <tr>
              <td> {{"home-animals" | transloco}}</td>
              <td> 50 zł</td>
              <td> 50 zł</td>
              <td> 50 zł</td>
              <td> 50 zł</td>
            </tr>
            <tr>
              <td> {{"touristTax" | transloco}}</td>
              <td> 2,50 zł</td>
              <td> 2,50 zł</td>
              <td> 2,50 zł</td>
              <td> 2,50 zł</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3 class="topic">{{"priceIncludes" | transloco}}</h3>


      <span class="spanFont10">- {{"nightWithBreakfast" | transloco}},</span><br />
      <span class="spanFont10">- {{"taxVat" | transloco}}</span> <br />
      <!-- <span class="spanFont10">- {{"additionalFeeForEaster" | transloco}} :190 zł
        {{"person" | transloco}}</span> -->

      <hr />
      <h3 class="topic" style="color:black;">{{"prices" | transloco}} &middot; {{"packages.sevendays" | transloco}} &middot; 2024</h3>
      <hr />
      <div style="overflow-x:auto;">
        <table class="priceTableSmallText" style="font-size: 18px; width: 100%;" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <th></th>
              <th>{{"season" | transloco}} A<br><br>02.01 - 31.03<br>01.11 - 22.12</th>
              <th>{{"season" | transloco}} B<br><br>01.04 - 30.04<br>01.10 - 31.10</th>
              <th>{{"season" | transloco}} C<br><br>01.05 - 30.06<br>01.09 - 30.09</th>
              <th>{{"season" | transloco}} D<br><br>01.07 - 31.08</th>
            </tr>

            <tr>
              <td>
                {{"double-room" | transloco}}<br>
                / {{"pricePerOnePerson" | transloco}} {{"with-breadfast-and-evening-meal" | transloco}} /
              </td>
              <td> 1390 zł</td>
              <td> 1669 zł</td>
              <td> 2029 zł</td>
              <td> 2469 zł</td>
            </tr>

            <tr>
              <td>
                {{"double-room" | transloco}}<br>
                / {{"pricePerOnePerson" | transloco}} {{"with-breadfast" | transloco}} /
              </td>
              <td> 910 zł</td>
              <td> 1190 zł</td>
              <td> 1619 zł</td>
              <td> 2049 zł</td>
            </tr>

            <tr>
              <td> {{"double-room" | transloco}}<br>
                / {{"for-single-use" | transloco}} /</td>
              <td> + 40%</td>
              <td> + 40%</td>
              <td> + 40%</td>
              <td> + 40%</td>
            </tr>
            
            <tr>
              <td> {{"kidsUnderFour" | transloco}}
                <br/>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
              <td> {{"free" | transloco}}</td>
            </tr>

            <tr>
              <td> {{"kids-between-5-12" | transloco}}<br>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> 50%</td>
              <td> 50%</td>
              <td> 50%</td>
              <td> 50%</td>
            </tr>

            <tr>
              <td> {{"kids-over-13" | transloco}}<br>
                ({{"with-two-fullypaid-parents" | transloco}})
              </td>
              <td> 80%</td>
              <td> 80%</td>
              <td> 80%</td>
              <td> 80%</td>
            </tr>

            <tr>
              <td> {{"seaView" | transloco}} <br/>
              / {{"day" | transloco}} /
              </td>
              <td>20 zł</td>
              <td>30 zł</td>
              <td>40 zł</td>
              <td>60 zł</td>
            </tr>

            <tr>
              <td> {{"parking" | transloco}} <br/>
              / {{"day" | transloco}} /
              </td>
              <td> 20 zł </td>
              <td> 30 zł </td>
              <td> 30 zł </td>
              <td> 40 zł</td>
            </tr>

            <tr>
              <td> {{"bedForChild" | transloco}}</td>
              <td> 30 zł</td>
              <td> 40 zł</td>
              <td> 40 zł</td>
              <td> 50 zł</td>
            </tr>

            <tr>
              <td> {{"touristTax" | transloco}}</td>
              <td> 2,50 zł</td>
              <td> 2,50 zł</td>
              <td> 2,50 zł</td>
              <td> 2,50 zł</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br>
      <span style="font-size: 20px;"> *{{"withTwoFullyPaid" | transloco}}</span>
      <br />
      <span style="font-size: 20px;">{{"moreAt" | transloco}} <a
          href="/packets/sevendays">www.borgata.pl/packets/sevendays</a></span>
      <hr />
      <h3>{{"foodPrices" | transloco}}</h3>

      <hr />
      <table class="priceTable" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td class="pricesTopic">
              {{"breakfast" | transloco}}
            </td>
            <td>
              {{"adults" | transloco}} 60 zł / {{"kids" | transloco}} 40 zł
            </td>
          </tr>
          <tr>
            <td class="pricesTopic">
              {{"dinner" | transloco}}
            </td>
            <td>
              {{"adults" | transloco}} 60 zł / {{"kids" | transloco}} 40 zł
            </td>
          </tr>
          <tr>
            <td class="pricesTopic">
              {{"dinnerEveningMeal" | transloco}}
            </td>
            <td>
              {{"adults" | transloco}} 80 zł / {{"kids" | transloco}} 45 zł
            </td>
          </tr>
        </tbody>
      </table>
      <hr>
      <h5>
        {{"borgataPricesRights" | transloco}}
      </h5>

      <div style="height:50px;"></div>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>