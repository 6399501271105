<style>
  @import url('https://fonts.googleapis.com/css?family=Armata');
</style>
<div id="rectangles" class="bg">
  <div id="backButtonRectangles" class="menuLogo" [routerLink]="'/' | localize" routerLinkActive="active" style="display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;"> {{"backSecond" | transloco }}
    </div>
  <div class="rectanglesRow">
    <div class="rectangle vouchers" [routerLink]="'/vouchers' | localize" (mouseenter)="raisingEffect('vouchers')" (mouseleave)="hideEffect('vouchers')">
      <div class="centerRectangleText">
          {{"vouchers" | transloco }}
        <div id="vouchersText" class="smallRectangleText">
            {{"vouchersText" | transloco }}
        </div>
      </div>
    </div> 
    <div class="rectangle news" [routerLink]="'/prices' | localize" routerLinkActive="active" (mouseenter)="raisingEffect('news')" (mouseleave)="hideEffect('news')">
      <div class="centerRectangleText">
          {{"prices" | transloco }}
        <div id="newsText" class="smallRectangleText">
            {{"prices" | transloco }} 2024
        </div>
      </div>
    </div>
    <div class="rectangle rooms" [routerLink]="'/gallery' | localize" routerLinkActive="active" (mouseenter)="raisingEffect('rooms')" (mouseleave)="hideEffect('rooms')">
      <div class="centerRectangleText">{{"gallery" | transloco }}
          <div id="roomsText" class="smallRectangleText">
              {{"galleryText" | transloco }}
            </div>
      </div>
    </div>
    
    <div class="rectangle spa" [routerLink]="'/spa' | localize" routerLinkActive="active" (mouseenter)="raisingEffect('spa')" (mouseleave)="hideEffect('spa')">
      <div class="centerRectangleText">{{"spa" | transloco }}
          <div id="spaText" class="smallRectangleText">
              {{"spaText" | transloco }}
          </div>
      </div>
    </div>

  </div>

  <div class="rectanglesRow">

    <div class="rectangle rental" [routerLink]="'/rental' | localize" routerLinkActive="active" (mouseenter)="raisingEffect('rental')" (mouseleave)="hideEffect('rental')">
      <div class="centerRectangleText">{{"wypozyczalnia" | transloco }}
          <div id="rentalText" class="smallRectangleText">
              {{"wypozyczalnia_info" | transloco }}
          </div>
      </div>
    </div>

    <div class="rectangle packets" [routerLink]="'/packets' | localize" routerLinkActive="active" (mouseenter)="raisingEffect('packets')" (mouseleave)="hideEffect('packets')">
      <div class="centerRectangleText">{{"packets" | transloco }}
          <div id="packetsText" class="smallRectangleText">
              {{"packetsText" | transloco }}
          </div>
      </div>
    </div>
    <div class="rectangle specialOffers" [routerLink]="'/occasional-offers' | localize" routerLinkActive="active" (mouseenter)="raisingEffect('offers')" (mouseleave)="hideEffect('offers')">
      <div class="centerRectangleText">{{"occasionalOffers" | transloco }}
        <div id="offersText" class="smallRectangleText">
            {{"occasionalOffersText" | transloco }}
        </div>
      </div>
    </div>
    <div class="rectangle contact" [routerLink]="'/contact' | localize" routerLinkActive="active" (mouseenter)="raisingEffect('contact')" (mouseleave)="hideEffect('contact')">
      <div class="centerRectangleText">{{"contact" | transloco }}
        <div id="contactText" class="smallRectangleText">
            {{"contactText" | transloco }}
        </div></div>
    </div>
  </div>
</div>