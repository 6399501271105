<h3 class="heading">{{ "additionalInformations" | transloco }}</h3>
<div class="infoBox">
  <ul>
    <li>
      <span class="title">{{ "breakfast" | transloco }}</span>
      <span class="text">{{ "from" | transloco }} 08:00 {{ "to" | transloco}} 10:00</span>
    </li>
    <li>
      <span class="title">{{ "lateDinner" | transloco }}</span>
      <span class="text">{{ "from" | transloco }} 16:00 {{ "to" | transloco}} 18:30</span>
    </li>
    <li>
      <span class="title">{{ "sauna" | transloco }}</span>
      <span class="text">{{ "from" | transloco }} 14:00 {{ "to" | transloco}} 21:00</span>
    </li>
    <li>
      <span class="title">{{ "cafe" | transloco }}</span>
      <span class="text">{{ "from" | transloco }} 14:00 {{ "to" | transloco}} 21:00</span>
    </li>
  </ul>
</div>
