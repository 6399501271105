import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import {} from 'googlemaps';
@Component({
standalone: true,
imports: [TranslocoModule, CommonModule],
  selector: "app-attractions",
  templateUrl: "./attractions.component.html",
  styleUrls: ["./attractions.component.css"]
})
export class AttractionsComponent implements OnInit {
  constructor() {}
  google
  @ViewChild("gmap")
  gmapElement: any;
  map: google.maps.Map;
  marker: google.maps.Marker;
  borgataMarker: google.maps.Marker;

  ngOnInit() {
    let mapProp = {
      center: new google.maps.LatLng(54.2196436, 15.7664011),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    let position = { lat: 54.219426, lng: 15.7688 };

    

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.borgataMarker = new google.maps.Marker({
      position: position,
      map: this.map
    });
    this.marker = new google.maps.Marker({ position: position, map: this.map });
  }
  resetSpans() {
    let elements = [];
    for (let i = 1; i <= 13; i++) {
      let x = "item-" + i;
      elements.push(x);
    }
    for (let i = 1; i <= elements.length; i++) {
      let x = document.getElementById(elements[i - 1]);
      if (x != null) {
        x.style.fontSize = "15px";
        x.style.fontWeight = "400";
      }
    }
  }
  setChoosedSpan(i) {
    let elements = [];
    for (let i = 1; i <= 13; i++) {
      let x = "item-" + i;
      elements.push(x);
    }
    let x = document.getElementById(elements[i - 1]);
    if (x != null) {
      x.style.fontSize = "17px";
      x.style.fontWeight = "900";
    }
  }
  changeMarkerPos(value) {
    let position = { lat: 0, lng: 0 };
    this.resetSpans();
    this.setChoosedSpan(value);
    //Park
    if (value == 1) {
      position = { lat: 54.218491, lng: 15.771017 };
      this.map.setZoom(15);
      this.map.setCenter(position);
    }
    //Korty
    else if (value == 2) {
      position = { lat: 54.215073, lng: 15.763817 };
      this.map.setZoom(13);
      this.map.setCenter(position);
    }
    //Western
    else if (value == 3) {
      position = { lat: 54.207498, lng: 15.747292 };
      this.map.setZoom(12);
      this.map.setCenter(position);
    }
    //Ogrody tematyczne
    else if (value == 4) {
      position = { lat: 54.181508, lng: 15.931621 };
      this.map.setZoom(11);
      this.map.setCenter(position);
    }
    //Latarnia morska w Gąskach
    else if (value == 5) {
      position = { lat: 54.242842, lng: 15.872845 };
      this.map.setZoom(10);
      this.map.setCenter(position);
    }
    //Najstarsze Dęby w Polsce
    else if (value == 6) {
      position = { lat: 54.180873, lng: 15.695812 };
      this.map.setZoom(10);
      this.map.setCenter(position);
    }
    //Wieża widokowa w Kukince
    else if (value == 7) {
      position = { lat: 54.187262, lng: 15.755863 };
      this.map.setZoom(12);
      this.map.setCenter(position);
    }
    //HELIOS
    else if (value == 8) {
      position = { lat: 54.2150593, lng: 15.7616231 };
      this.map.setZoom(15);
      this.map.setCenter(position);
    }
    //Wesołe miasteczko
    else if (value == 9) {
      position = { lat: 54.2116702, lng: 15.7438971 };
      this.map.setZoom(13);
      this.map.setCenter(position);
    }
    //Park rozrywki POMERANIA
    else if (value == 10) {
      position = { lat: 54.1217295, lng: 15.7538101 };
      this.map.setZoom(10);
      this.map.setCenter(position);
    }
    //Boiska sportowe
    else if (value == 11) {
      position = { lat: 54.215944, lng: 15.767384 };
      this.map.setZoom(15);
      this.map.setCenter(position);
    }
    //Oceanarium
    else if (value == 12) {
      position = { lat: 54.1793188, lng: 15.566598 };
      this.map.setZoom(11);
      this.map.setCenter(position);
    }
    //latarnia morska w Kolobrzegu
    else if (value == 13) {
      position = { lat: 54.1864, lng: 15.5520423 };
      this.map.setZoom(11);
      this.map.setCenter(position);
    }

    this.marker.setPosition(position);
  }
}
