import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavBarService } from '../navbar.service';
import { DataService } from '../data.service';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from 'app/shared/components/language-selector/language-selector.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';
import { take } from 'rxjs';


@Component({
  standalone: true,
  imports: [TranslocoModule, CommonModule, LanguageSelectorComponent, LocalizeRouterModule, RouterModule],
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.css'],
})
export class VouchersComponent implements AfterViewInit {

  categories: any = null;
  constructor(
    public navBarService: NavBarService,
    public dataService: DataService,
    public translate: TranslocoService) {

    this.dataService.currentVouchers
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.categories = data
        }
      })

    this.navBarService.hide()

  }

  ngAfterViewInit(): void {
    this.dataService.getVouchers();
  }

  calculateSale(value, normalBuyvalue) {
    let number = normalBuyvalue * 100 / value;
    let newValue = 100 - number;
    return Math.round(newValue)
  }

  onWindowScroll() {
    let firstRow = document.getElementById("pageTopRectanglesRow")
    firstRow.style.height = "10%";
    firstRow.style.transition = "1s";
    let secondRow = document.getElementById("pageRectanglesRow")
    secondRow.style.height = "90%"
  }
}
