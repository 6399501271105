<div class="bgReception" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/menu' | localize" routerLinkActive="active">
      {{"backSecond" | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox" style="padding: 20px 20px;">
      <div class="row">
        <div class="col-md-12" style="font-size: 18px;">
          <h3>{{"filesToDownload" | transloco}}</h3>
          <hr />
          <div style="height:100px;"></div>
          <a href="/assets/files/Regulamin.docx">Regulamin.docx</a> <br /> <br/>
          <a href="/assets/files/Vorschriften.docx">Vorschriften.docx</a>
          <div style="height:100px;"></div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6" style="font-size: 18px;">
          <br >
          Tel. (94) 35 15 661
          <br> Tel. +48 604 504 112
          <br>
          <br>
          {{"email" | transloco }} <br /> recepcja&#64;borgata.pl
          <br>
        </div>
        <div class="col-md-6" style="font-size: 18px;">
          <br>
          <b>Borgata</b> <br>
          {{"streetShort" | transloco }} Spokojna 7 <br>
          78-111 Ustronie Morskie <br>
          {{"poland" | transloco}}
        </div>
      </div>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>