<div class="bg" (wheel)="onWindowScroll()">
    <div id="pageTopRectanglesRow">
      <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/news' | localize" routerLinkActive="active">{{"backSecond" | transloco }}</div>
      <div class="pageTopMarginBox">
      </div>
      <div class="pageMarginBox BoxFadeInFromRight"></div>
    </div>
    <div id="pageRectanglesRow">
      <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
      <div id="content" class="pageContentBox">
        <div class="newsContent">
          <div style="height:10px;"></div>
        <h2>{{newsData?.title}}</h2>
        <h5>{{newsData?.day}}-{{newsData?.month}}-{{newsData?.year}}</h5>
        <p style="text-align: justify;">
          {{newsData?.description}}
        </p>
        </div>
        
      </div>
      <div class="pageSideMarginBox BoxFadeInFromRight"></div>
    </div>
  </div>