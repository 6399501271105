<style>
  @import url('https://fonts.googleapis.com/css?family=Armata');

</style>
<div id="rectangles" class="bgRooms">
  <div id="backButtonRectangles" class="menuLogo" [routerLink]="'/menu' | localize" routerLinkActive="active" style="display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;"> {{"backSecond" | transloco }}
  </div>
  <div class="rectanglesRow">
    <div class="rectangle gallery50" (click)="showImages('rooms')" >
      <div class="centerRectangleText">
          {{"rooms" | transloco }}
      </div>
    </div>
    <div class="rectangle spa30" (click)="showImages('spa')" >
      <div class="centerRectangleText">{{"spa" | transloco }}
      </div>
    </div>
    <div class="rectangle ext20" (click)="showImages('ext')" >
      <div class="centerRectangleText">{{"exterior" | transloco }}
      </div>
    </div>
  </div>
  <div class="rectanglesRow">
    <div class="rectangle eat50" (click)="showImages('eat')" >
      <div class="centerRectangleText">{{"eat" | transloco }}
      </div>
    </div>
    <div class="rectangle cafe30" (click)="showImages('cafe')" >
      <div class="centerRectangleText">{{"cafe" | transloco }}
      </div>
    </div>
    <div class="rectangle int20" (click)="showImages('int')">
      <div class="centerRectangleText">{{"interior" | transloco }}
      </div>
    </div>
  </div>
</div>
