<div *ngIf="!videoLoaded" class="loading-overlay">
  <mat-spinner></mat-spinner>
  <div class="loading-text">
    {{ 'loading' | transloco }}<br/>
    <small>{{'please-be-patient-for-a-moment' | transloco}}</small>
  </div>
</div>

<div class="video-background">
  <video #videoElement autoplay muted loop (canplaythrough)="onVideoLoaded()">
    <source src="assets/videos/borgata.mp4" type="video/mp4">
    <source src="assets/videos/borgata.webm" type="video/webm">
    Your browser does not support the video tag.
  </video>
</div>

<div *ngIf="videoLoaded" class="text-center" (wheel)="onWindowScroll()">
  <div class="welcome-space"></div>
  <div class="mx-auto  border-slate-500 px-2 py-2 logo-container" drop-shadow-lg shadow-lg>
    <div class="centerLogo"> </div>
  </div>
  <div class="mx-auto drop-shadow-lg  text1">
    {{"frontText" | transloco }}
  </div>
  <div class="mx-auto drop-shadow-lg text3">
    {{"secondFrontText" | transloco }}
  </div>

  <div class="d-flex  flex-center" style="margin-top:55px;">
    <a class="text-2xl drop-shadow-lg shadow-lg mr-2 rounded min-h-14 max-w-fit bg-orange-600/75 hover:bg-sky-400 text-white decoration-0 p-3 px-8 fade-in"
      [routerLink]="'/menu' | localize" routerLinkActive="active">{{"frontButton" |
      transloco }} &#10095;
    </a>

    <!-- <a class="text-2xl drop-shadow-lg shadow-lg ml-2 rounded min-h-14 max-w-fit  bg-emerald-600/75 hover:bg-green-400 text-white decoration-0 p-3 px-4 fade-in"
      [routerLink]="'/vouchers' | localize" routerLinkActive="active">{{"buyVoucher" | transloco }} &#10095;</a> -->
  </div>

  
<a href="https://voucherries.com/borgata" target="_blank">
  <div class="bannerOffer1 bannerOffer cursor-pointer" style=" background-image: url('./assets/vouchers.jpeg');">
    <div class="centerRectangleText" style="background-color: rgba(0, 0, 0, 0.527);padding-top:5px; padding-bottom:5px;">{{'buyVoucher' | transloco}} 
      <br> <span class="bannerOfferText" style="color:red">{{"from"  | transloco }} 245 zł</span>
    </div>
  </div>
</a>


<!-- <div class="bannerOffer2 bannerOffer cursor-pointer" [routerLink]="'/occasional-offers/easter' | localize" routerLinkActive="active" style=" background-image: url('./assets/easter.jpg');">
  <div class="centerRectangleText" style="background-color: rgba(0, 0, 0, 0.527);padding-top:5px; padding-bottom:5px;">{{"packages.easter" | transloco}} 
    <br> <span class="bannerOfferText">{{"from"  | transloco }} 1019 zł</span>
  </div>
</div> -->
  <!-- -->
  <!-- <div class="facebookBox"><a href="https://www.facebook.com/borgatahotel/" target="_blank">{{"likeUsFb" | transloco }}</a></div> -->
  <!-- <div class="instagramBox"><a href="https://www.instagram.com/borgatahotel/" target="_blank">{{"likeUsIng" | transloco }}</a></div> -->
  <!-- <div class="hiddenFrom1000px" [routerLink]="'/occasional-offers/winter-holidays' | localize" routerLinkActive="active" style=" cursor: pointer;position:fixed; bottom:40%; right:0; height: 200px; width:300px; background-image: url('./assets/winterHolidays.jpg');color:white; background-position:center; background-size: cover;">
      <div class="centerRectangleText" style="background-color: rgba(0, 0, 0, 0.527);padding-top:5px; padding-bottom:5px;">{{"packages.winterHolidays" | transloco}} 2023
        <br> <span style="font-size: 24px; color: goldenrod">{{"from"  | transloco }} 799zł</span></div>
    </div>
    <div class="hiddenFrom1000px" [routerLink]="'/occasional-offers/christmas' | localize" routerLinkActive="active" style=" cursor: pointer;position:fixed; bottom:calc(40% + 200px); right:0; height: 200px; width:300px; background-image: url('./assets/christmas.jpeg');color:white; background-position:center; background-size: cover;">
      <div class="centerRectangleText" style="background-color: rgba(0, 0, 0, 0.527);padding-top:5px; padding-bottom:5px;">{{"packages.christmas" | transloco}} 2023
        <br> <span style="font-size: 24px; color: goldenrod">{{"from"  | transloco }} 1490zł</span></div>
    </div> -->
  <!-- <div class="bannerInfo" [routerLink]="'/contact' | localize" routerLinkActive="active">
      <img src="/assets/icons/tarcza.svg" style="height: 100px;position:absolute; top:0;left:0;margin-top:-70px;" alt="">
      
      <div class="centerRectangleText"> 
        
        <b>Realizujemy Bony Turystyczne</b>
        <br> <span class="bannerOfferText">Skontaktuj się z nami</span>
        <br/>
        <img src="assets/bon-tur.png" style="width:100%;" alt="">
      </div>
    </div> -->

  <!-- <div class="bannerOffer2 bannerOffer" [routerLink]="'/occasional-offers/new-years-eve' | localize" routerLinkActive="active" style=" background-image: url('./assets/sylwester1.jpg');">
      <div class="centerRectangleText" style="background-color: rgba(0, 0, 0, 0.527);padding-top:5px; padding-bottom:5px;">{{"packages.newYear" | transloco}} 
        <br> <span class="bannerOfferText">{{"from"  | transloco }} 899zł</span>
      </div>
    </div> -->


  <!-- <div class="bannerOffer" [routerLink]="'/occasional-offers/christmas' | localize" routerLinkActive="active" style=" background-image: url('./assets/christmas.jpeg');">
      <div class="centerRectangleText" style="background-color: rgba(0, 0, 0, 0.527);padding-top:5px; padding-bottom:5px;">{{"packages.christmas" | transloco}} 
        <br> <span class="bannerOfferText">{{"from"  | transloco }} 1190zł</span></div>
    </div> -->


  <!-- 
  
    <div class="bannerOffer3 bannerOffer" [routerLink]="'/occasional-offers/winter-holidays' | localize" routerLinkActive="active" style=" background-image: url('./assets/winterHolidays.jpg');">
      <div class="centerRectangleText" style="background-color: rgba(0, 0, 0, 0.527);padding-top:5px; padding-bottom:5px;">{{"packages.winterHolidays" | transloco}} 
        <br> <span class="bannerOfferText">{{"from"  | transloco }} 939 zł</span></div>
    </div> -->
</div>