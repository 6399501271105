import { Component, OnInit } from '@angular/core';
import { NavBarService } from '../navbar.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';

@Component({
  standalone: true,
  imports: [TranslocoModule, CommonModule, LocalizeRouterModule, RouterModule],
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent {

  constructor(
    public translate: TranslocoService,
    public navBarService: NavBarService
  ) {
    this.navBarService.show()
  }


  showImages(x) {

    let check = document.getElementById('gallery')
    if (check != null) {
      check.remove()
      let rectangles = document.getElementById("rectangles")
      rectangles.style.display = "none"
    }


    let type = x
    let imagesRoom =
      [
        "room1.jpg",
        "room2.jpg",
        "room3.jpg",
        "room4.jpg",
        "room5.jpg",
        "room6.jpg",
        "room7.jpg",
        "room8.jpg",
        "room9.jpg"
      ]
    let imagesSpa =
      [
        "spa1.jpg",
        "spa2.jpg",
        "spa3.jpg",
        "spa4.jpg"
      ]
    let imagesExt =
      [
        "ext1.jpg",
        "ext2.jpg",
        "ext3.jpg",
        "ext4.jpg",
        "ext5.jpg",
        "ext6.jpg",
        "ext7.jpg"
      ]
    let imagesEat =
      [
        "eat1.jpg",
        "eat2.jpg",
        "eat3.jpg",
        "eat4.jpg",
        "eat5.jpg",
        "eat6.jpg",
        "eat7.jpg"
      ]
    let imagesCafe =
      [
        "cafe1.jpg",
        "cafe2.jpg",
        "cafe3.jpg",
        "cafe4.jpg",
        "cafe5.jpg"
      ]
    let imagesInt =
      [
        "int1.jpg",
        "int2.jpg",
        "int3.jpg",
        "int4.jpg"
      ]
    let selected = 0
    let image = document.createElement("div")
    image.id = "gallery"
    image.style.position = "absolute";
    image.style.backgroundColor = 'rgba(0,0,0,0.7';

    let array = new Array();
    if (type == "rooms") array = Array.from(imagesRoom)
    else if (type == "spa") array = imagesSpa
    else if (type == "ext") array = imagesExt
    else if (type == "eat") array = imagesEat
    else if (type == "cafe") array = imagesCafe
    else if (type == "int") array = imagesInt

    image.style.backgroundImage = "url('/assets/" + array[selected] + "')"
    image.style.top = "0"
    image.style.height = "100%"
    image.style.width = "100%"
    image.style.zIndex = "99999"
    image.style.backgroundPosition = "center"
    image.style.backgroundRepeat = "no-repeat"
    image.style.backgroundSize = "cover"
    image.style.boxSizing = "inherit"

    let closeButton = document.createElement("button")
    closeButton.innerText = this.translate.translate('galleryToolbar.close')
    closeButton.style.width = "auto"
    closeButton.style.height = "30px"
    closeButton.style.backgroundColor = "black"
    closeButton.style.color = "white"
    closeButton.style.position = "absolute"
    closeButton.style.top = "0"
    closeButton.style.right = "0"

    let nextButton = document.createElement("button")
    nextButton.innerText = this.translate.translate('galleryToolbar.next')
    nextButton.style.width = "auto"
    nextButton.style.height = "30px"
    nextButton.style.backgroundColor = "black"
    nextButton.style.color = "white"
    nextButton.style.position = "absolute"
    nextButton.style.bottom = "50%"
    nextButton.style.right = "0"

    let prevButton = document.createElement("button")
    prevButton.innerText = this.translate.translate('galleryToolbar.previous')
    prevButton.style.width = "auto"
    prevButton.style.height = "30px"
    prevButton.style.backgroundColor = "black"
    prevButton.style.color = "white"
    prevButton.style.position = "absolute"
    prevButton.style.bottom = "50%"
    prevButton.style.left = "0"

    image.appendChild(closeButton)
    image.appendChild(nextButton)
    image.appendChild(prevButton)

    closeButton.addEventListener('click', function () {
      image.remove()
    })
    nextButton.addEventListener('click', function () {
      selected++
      if (selected >= array.length) selected = 0
      let element = document.getElementById("gallery")
      element.style.backgroundImage = "url('/assets/" + array[selected] + "')"

    })
    prevButton.addEventListener('click', function () {
      selected--
      if (selected < 0) selected = array.length - 1
      let element = document.getElementById("gallery")
      element.style.backgroundImage = "url('/assets/" + array[selected] + "')"
    })

    let rectangles = document.getElementById("rectangles")
    rectangles.appendChild(image);

  }


}
