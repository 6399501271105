
<div id="rectangles" class="bgPackets">
  <div id="backButtonRectangles" class="menuLogo" [routerLink]="'/menu' | localize" routerLinkActive="active" style="display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;"> {{"backSecond" | transloco }}
  </div>
  <div class="rectanglesRow">
    <div class="rectangle top_1_25" [routerLink]="'/packets/health' | localize" routerLinkActive="active">
      <div class="centerRectangleText" >
          {{"packages.health" | transloco}} 2024
      </div>
    </div>
    <div class="rectangle top_2_25" [routerLink]="'/packets/medikur' | localize" routerLinkActive="active">
      <div class="centerRectangleText">
        {{"packages.mediKur" | transloco}} 2024
      </div>
    </div>
    <div class="rectangle top_3_25 disabled" >
      <!-- <div class="centerRectangleText">
        {{"packages.relax" | transloco}}
      </div> -->
    </div>
    <div class="rectangle top_4_25" [routerLink]="'/packets/weekendspa' | localize" routerLinkActive="active">
      <div class="centerRectangleText">
        {{"packages.weekendSpa" | transloco}} 2023
      </div>
    </div>
  </div>
  <div class="rectanglesRow">
    <div class="rectangle bottom_1_25" [routerLink]="'/packets/ladies-relax' | localize" routerLinkActive="active">
      <div class="centerRectangleText">
          {{"packages.womensRelax" | transloco}} 2023
      </div>
    </div>
    <div class="rectangle bottom_2_25" [routerLink]="'/packets/mens-relax' | localize" routerLinkActive="active">
      <div class="centerRectangleText">
        {{"packages.mensRelax" | transloco}} 2023
      </div>
    </div>
    <div class="rectangle bottom_3_25" [routerLink]="'/packets/sevendays' | localize" routerLinkActive="active">
      <div class="centerRectangleText">
        {{"packages.sevendays" | transloco}} 2024
      </div>
    </div>
    <div class="rectangle bottom_4_25" [routerLink]="'/packets/senior' | localize" routerLinkActive="active">
      <div class="centerRectangleText">
        {{"packages.senior" | transloco}} 2023
      </div>
    </div>
  </div>
</div>