<style>
    @import url('https://fonts.googleapis.com/css?family=Armata');
  </style>
  <div class="bg" style="background-image: url('/assets/couplespa.jpeg')" (wheel)="onWindowScroll()">
    <div id="pageTopRectanglesRow">
      <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/packets' | localize" routerLinkActive="active">{{"backSecond" | transloco}}</div>
      <div class="pageTopMarginBox">
      </div>
      <div class="centered pageMarginBox BoxFadeInFromRight">
        <app-language-selector></app-language-selector>
      </div>
    </div>
    <div id="pageRectanglesRow">
      <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
      <div id="content" class="pageContentBox">
        <h3 class="topic" style="color:#802400;">{{"packages.womensRelax" | transloco}} 2023</h3>
        <br/>
        
        <h3 class="heading">{{"packageIncludes" | transloco}}</h3>
        <div class="infoBox">
          <ul>
            <li>
              <span class="title">{{"alimentation" | transloco}}</span>
              <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"greeting" | transloco}}</span>
              <span class="text">{{"bottleWine" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"treatment" | transloco}}</span>
              <span class="text">{{"bodyPeeling" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"massage" | transloco}}</span>
              <span class="text">{{"neckAndCleavageMassage" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"treatment" | transloco}}</span>
              <span class="text">{{"moisturizingTreatment" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"sauna" | transloco}}</span>
              <span class="text">{{"unlimitedSauna" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"aquapark" | transloco}}</span>
              <span class="text">{{"oneTimeAquaparkAccess" | transloco}}</span>
            </li>
            <li>
              <span class="text">{{"aquaparkAccessLimit" | transloco}}</span>
            </li>
          </ul>
        </div>
        <app-we-provide></app-we-provide>
        <app-attractions></app-attractions>
        <h3 class="heading">{{"pricePerPerson" | transloco}}</h3>
        <h3 class="heading">{{"twoNights" | transloco}}</h3>
        <div style="overflow-x:auto;">
          <table class="priceTableSmallText" style="font-size: 18px; width: 100%;" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <th></th>
                <th>{{"season" | transloco}} A<br><br>02.01 - 31.03<br>01.11 - 22.12</th>
                <th>{{"season" | transloco}} B<br><br>01.04 - 30.04<br>01.10 - 31.10</th>
                <th>{{"season" | transloco}} C<br><br>01.05 - 25.06<br>31.08 - 30.09</th>
                <th>{{"season" | transloco}} D<br><br>26.06 - 30.08</th>
              </tr>
  
              <tr>
                <td>
                  <b>{{"double-room" | transloco}}</b>
                  <br>
                  / {{"pricePerOnePerson" | transloco}} /
                </td>
                <td> 620 zł</td>
                <td> 670 zł</td>
                <td> 770 zł</td>
                <td> 930 zł</td>
              </tr>
  
            </tbody>
          </table>
        </div>
  
        
        <h3 class="heading">{{"additionalFees" | transloco}}</h3>
        <div class="infoBox">
          <ul>
            <li>
              <span class="title">{{"parking" | transloco}}</span>
              <span class="text">{{"from" | transloco}} 20 zł {{"to" | transloco}} 40 zł {{"day" | transloco}} <br/> {{"depends-on-season" | transloco}}</span>
            </li>
            <li>
              <span class="title">{{"touristTax" | transloco}}</span>
              <span class="text">2,50 zł {{"day" | transloco}}/{{"person" | transloco}}</span>
            </li>
          </ul>
        </div>
        <additional-informations></additional-informations>
      </div>
      <div class="pageSideMarginBox BoxFadeInFromRight"></div>
    </div>
  </div>