<h3 class="heading">{{"near"  | transloco }}</h3>
<div class="infoBox">
  <div class="row">
    <div class="col-md-6 infoBoxContent">
    <hr/><span id="item-1" class="paddingTop20  pointer" (click)="changeMarkerPos(1)">{{"attractions.ropePark"  | transloco }} <small> - 150 m - {{"onFoot"  | transloco }}</small></span> 
    <hr/><span id="item-2" class="paddingTop20  pointer" (click)="changeMarkerPos(2)">{{"attractions.tennisCourts"  | transloco }}<small> - 550 m - {{"onFoot"  | transloco }}</small></span> 
    <hr/><span id="item-3" class="paddingTop20  pointer" (click)="changeMarkerPos(3)">{{"attractions.westernPark"  | transloco }} <small> - 3 km - {{"bike"  | transloco }}</small></span> 
    <hr/><span id="item-4" class="paddingTop20  pointer" (click)="changeMarkerPos(4)">{{"attractions.hortulus"  | transloco }}<small> - 17 km - {{"car"  | transloco }}</small></span> 
    <hr/><span id="item-5" class="paddingTop20  pointer" (click)="changeMarkerPos(5)">{{"attractions.lantern"  | transloco }}<small> - 15 km - {{"bike"  | transloco }}/{{"car"  | transloco }}</small></span> 
    <hr/><span id="item-6" class="paddingTop20  pointer" (click)="changeMarkerPos(6)">{{"attractions.oaks"  | transloco }}<small> - 9 km - {{"car"  | transloco }}</small></span> 
    <hr/><span id="item-7" class="paddingTop20  pointer" (click)="changeMarkerPos(7)">{{"attractions.tower"  | transloco }}<small> - 5 km - {{"car"  | transloco }}</small></span> 
    <hr/><span id="item-8" class="paddingTop20  pointer" (click)="changeMarkerPos(8)">{{"attractions.helios"  | transloco }}<small> - 900 m - {{"onFoot"  | transloco }}</small></span> 
    <hr/><span id="item-9" class="paddingTop20  pointer" (click)="changeMarkerPos(9)">{{"attractions.funnyPark"  | transloco }}<small> - 2.5 km - {{"onFoot"  | transloco }}</small></span> 
    <hr/><span id="item-10" class="paddingTop20  pointer" (click)="changeMarkerPos(10)">{{"attractions.pomerania"  | transloco }}<small> - 16 km - {{"car"  | transloco }}</small></span> 
    <hr/><span id="item-11" class="paddingTop20  pointer" (click)="changeMarkerPos(11)">{{"attractions.footballPitch"  | transloco }}<small> - 250 m - {{"onFoot"  | transloco }}</small></span> 
    <hr/><span id="item-12" class="paddingTop20  pointer" (click)="changeMarkerPos(12)">{{"attractions.oceanarium"  | transloco }}<small> - 15 km -  {{"bike"  | transloco }}/{{"car"  | transloco }}</small></span> 
    <hr/><span id="item-13" class="paddingTop20  pointer" (click)="changeMarkerPos(13)">{{"attractions.lanternKolberg"  | transloco }}<small> - 14 km -  {{"bike"  | transloco }}/{{"car"  | transloco }}</small></span> 
    <hr/>
    </div>
    <div class="col-md-6"><div #gmap id="googleMap" style="width:100%;height:500px"></div>
  </div>
  </div>
</div>