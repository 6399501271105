<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false" [opened]="false">
    <mat-toolbar color="primary"></mat-toolbar>
    <!-- <mat-nav-list>
      <a [routerLink]="'/' | localize" routerLinkActive="active" style="background-color:white;background-image:url('assets/BorgataPresents.png');width:165px;height:64px;background-repeat: no-repeat;background-position: center;"></a>
      <a mat-list-item [routerLink]="'/menu' | localize" routerLinkActive="active">Menu</a>
      <a mat-list-item [routerLink]="'/packets' | localize" routerLinkActive="active">{{"packets" | transloco }}</a>
    </mat-nav-list> -->
    <aside class="main_sidebar">
      <ul>
        <li style="color:white; margin-bottom:40px;">{{"navigation" | transloco }}</li>

        <li *ngFor="let item of menu">
          <a (click)="drawer.toggle()" [routerLink]="item.path | localize" routerLinkActive="active">
            <i><mat-icon>{{item.icon}}</mat-icon></i>
            <a>{{item.translation | transloco}}</a>
          </a>
        </li>

        <li style="margin-top:30px;">
          <i class="fa fa-facebook "></i>
          <a href="https://www.facebook.com/borgatahotel/" target="_blank" style="text-decoration: none;">Facebook</a>
        </li>

        <li>
          <i class="fa fa-instagram "></i>
          <a href="https://www.instagram.com/borgatahotel/" target="_blank" style="text-decoration: none;">Instagram</a>
        </li>


        <li style="margin-top:20px;">
          <i class="fa fa-refresh " style="margin-top:10px;"></i>
          <!-- <mat-form-field style="z-index: 9999; max-width: 100px!important;">
                    <mat-select [(value)]="selected">
                      <mat-option  *ngFor="let l of languages" (click)="changeLanguage(l.short)" [value]="l.short">
                       <span style="width:32px;height:32px;position:absolute;top:0;left:0; background-size: cover;background-repeat: no-repeat;" [style.background]="'url('+l.imgSM+')'">  </span> <span style="margin-left:32px;"> {{l.language}}</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
          <app-language-selector></app-language-selector>
        </li>
        <li style="font-size: 9px;position:relative;bottom:0;left:0%; margin-top: 140px;"><a
            href="http://fb.com/therrax" target="_blank">Created by &#64;therrax</a></li>
      </ul>
    </aside>

  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" *ngIf="showNavbar">
      <button class="bg-black-transparent" type="button" aria-label="Toggle sidenav" mat-icon-button
        (click)="drawer.toggle()" *ngIf="showNavbar">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <!-- <a *ngIf="!(isHandset$ | async)" [routerLink]="'/' | localize" routerLinkActive="active" style="box-sizing:border-box;border-top:1px solid black;border-bottom:1px solid black;margin-left: -20px;background-color:white;background-image:url('assets/BorgataPresents.png');width:165px;height:64px;background-repeat: no-repeat;background-position: center"></a> -->
      <!-- <a *ngIf="!(isHandset$ | async)" [routerLink]="'/menu' | localize" routerLinkActive="active">Menu</a>
      <a *ngIf="!(isHandset$ | async)" [routerLink]="'/packets' | localize" routerLinkActive="active">{{"packets" | transloco }}</a>
      <a *ngIf="!(isHandset$ | async)" [routerLink]="'/gallery' | localize" routerLinkActive="active">{{"gallery" | transloco }}</a>
      <a *ngIf="!(isHandset$ | async)" [routerLink]="'/prices' | localize" routerLinkActive="active">{{"prices" | transloco }}</a>
      <a *ngIf="!(isHandset$ | async)" [routerLink]="'/contact' | localize" routerLinkActive="active">{{"contact" | transloco }}</a>
      <a *ngIf="!(isHandset$ | async)" [routerLink]="'/virtual-walk' | localize" routerLinkActive="active" >{{"virtualWalk" | transloco }}</a>
      -->


      <span class="spacer"></span>
      <app-language-selector></app-language-selector>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>