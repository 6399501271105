<div class="bg" style="background-image: url('assets/spa2.jpg')" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/spa' | localize" routerLinkActive="active">{{"backSecond"
      | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h1>
        {{"physicalTreatments" | transloco}}
      </h1>
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <div style="overflow-x:auto;">
            <table style="width:100%;">
              <tbody>
                <tr style="font-weight: 900;">
                  <td>
                    {{"treatment" | transloco}}
                  </td>
                  <td>
                    {{"duration" | transloco}}
                  </td>
                  <td>
                    {{"priceIn" | transloco}} PLN
                  </td>
                </tr>
                <tr style="height:20px;">
                  <td colspan="3"></td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.diadynamics" | transloco}}</td>
                  <td> 10-15 min</td>
                  <td> 20 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.interferenceCurrents" | transloco}}</td>
                  <td> 15 min</td>
                  <td> 20 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.galvanization" | transloco}}</td>
                  <td> 10 min</td>
                  <td> 20 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.ionotophoresis" | transloco}}</td>
                  <td> 10-15 min</td>
                  <td> 25 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.mudCompresses" | transloco}}</td>
                  <td> 15 min</td>
                  <td> 30 zł</td>
                </tr>
                <tr>
                  <td> {{"physicalTreatment.magnetotherapy" | transloco}}</td>
                  <td> 15 min</td>
                  <td> 25 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.ultrasounds" | transloco}}</td>
                  <td> 5-10 min</td>
                  <td> 20 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.phonophoresis" | transloco}}</td>
                  <td> 5-10 min</td>
                  <td> 25 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.soluxLamp" | transloco}}</td>
                  <td> 10-15 min</td>
                  <td> 20 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.laserTherapy" | transloco}}</td>
                  <td> 10 min</td>
                  <td> 20 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.localCryotherapy" | transloco}}</td>
                  <td> 3 min</td>
                  <td> 25 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.lymphaticDrainage" | transloco}}</td>
                  <td> 15-20 min</td>
                  <td> 30 zł</td>
                </tr>
                <tr>
                  <td>{{"physicalTreatment.individualInhalations" | transloco}}</td>
                  <td> 10 min</td>
                  <td> 15 zł</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>

      <div style="height:10px;"></div>

    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>