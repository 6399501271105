<style>
  @import url('https://fonts.googleapis.com/css?family=Armata');

</style>
<div class="bg" style="background-image: url('/assets/spa1.jpg')" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/packets' | localize" routerLinkActive="active">{{"backSecond" | transloco}}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h1 class="topic" style="color:#009607;">{{"packages.relax" | transloco}}</h1>
      <br/>
      <h3 class="heading">{{"packageIncludes" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"alimentation" | transloco}}</span>
            <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"discount" | transloco}}</span>
            <span class="text">{{"discountTenForSPA" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"treatments" | transloco}}</span>
            <span class="text">{{"twoMudWraps" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"massage" | transloco}}</span>
            <span class="text">{{"sheaMassage" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sauna" | transloco}}</span>
            <span class="text">{{"unlimitedSauna" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"aquapark" | transloco}}</span>
            <span class="text">{{"aquaparkAccess" | transloco}}</span>
          </li>
          <li>
            <span class="text">{{"aquaparkAccessLimit" | transloco}}</span>
          </li>
        </ul>
      </div>
      <app-we-provide></app-we-provide>
      <app-attractions></app-attractions>
      <h3 class="heading">{{"pricePerPerson" | transloco}}</h3>
      <table class="priceTable table table-striped">
        <tbody>
          <tr>
            <td style="text-align: left;">&nbsp;</td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 02.01 {{"to" | transloco}} 28.04
              </p>
              <p>
                  {{"from" | transloco}} 01.11 {{"to" | transloco}} 22.12
              </p>
            </td>
            <td style="text-align: center;">
              <p>
                  {{"from" | transloco}} 28.04 {{"to" | transloco}} 31.05 
              </p>
              <p>
                  {{"from" | transloco}} 01.10 {{"to" | transloco}} 31.10
              </p>
            </td>
          </tr>
          <tr>
            <td class="topicTd" style="text-align: center;">
              <b>5 {{"nights" | transloco}}</b>
              
            </td>
            <td style="text-align: center;">
              <strong>929zł</strong>
            </td>
            <td style="text-align: center;">
              <strong>?zł</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 class="heading">{{"additionalFees" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"parking" | transloco}}</span>
            <span class="text">{{"from" | transloco}} 15 zł {{"to" | transloco}} 35 zł {{"day" | transloco}} <br/> {{"depends-on-season" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"touristTax" | transloco}}</span>
            <span class="text">2,50 zł {{"day" | transloco}}/{{"person" | transloco}}</span>
          </li>
        </ul>
      </div>
      <additional-informations></additional-informations>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>
