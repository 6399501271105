import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "../../../node_modules/rxjs";
import { map } from "rxjs/operators";
import { NavBarService } from "../navbar.service";
import { Subscription } from "rxjs/internal/Subscription";
import { OnChanges } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LanguageSelectorComponent } from "app/shared/components/language-selector/language-selector.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import {MatToolbarModule} from '@angular/material/toolbar';
import { LocalizeRouterModule } from "@penleychan/ngx-transloco-router";
import { RouterModule } from "@angular/router";
@Component({
  standalone: true,
  imports: [TranslocoModule, CommonModule, LanguageSelectorComponent, LocalizeRouterModule, RouterModule, MatSidenavModule, MatIconModule, MatButtonModule, MatToolbarModule],
  selector: "app-main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.css"]
})
export class MainNavComponent implements OnInit, OnChanges, OnDestroy {

  menu = [
    { path: '/menu', translation: 'menu', icon: 'home'},
    { path: '/packets', translation: 'packets', icon: 'star'},
    { path: '/occasional-offers', translation: 'occasionalOffers', icon: 'auto_fix_high'},
    { path: '/gallery', translation: 'gallery', icon: 'photo_camera'},
    { path: '/prices', translation: 'prices', icon: 'table_rows'},
    { path: '/organisation-of-events', translation: 'organisation-of-events', icon: 'wine_bar'},
    { path: '/contact', translation: 'contact', icon: 'phone'},
    { path: '/virtual-walk', translation: 'virtualWalk', icon: '3d_rotation'},
    { path: '/files', translation: 'filesToDownload', icon: 'download'},
    { path: '/vouchers', translation: 'buyVoucher', icon: 'shopping_cart'}
  ]
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  constructor(
    public translate: TranslocoService,
    private breakpointObserver: BreakpointObserver,
    public navBarService:NavBarService
  ) {
    // this.translate.setDefaultLang("pl");
    // this.translate.use("pl");
  }
  subscription:Subscription
  showNavbar:boolean = true;
  ngOnInit() {
    this.subscription = this.navBarService.loaderState.subscribe(
      (data: boolean) => {
        this.showNavbar = data
      }
    );
  }
  ngOnChanges() {
    this.subscription = this.navBarService.loaderState.subscribe(
      (data: boolean) => {
        this.showNavbar = data
      }
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
