<div class="bgOffers" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/menu' | localize" routerLinkActive="active">
      {{"backSecond" | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div class="pageContentBox" style="font-size: 18px; padding: 0px 0px 0px 0px !important;">


     

      <div class="imageRow" [routerLink]="'/occasional-offers/winter-holidays' | localize" routerLinkActive="active">
        <!-- <div class="imageRow"> -->
        <div class="background" style="background-image: url('assets/winterHolidays.jpg')">
          <div class="textCenter">
            {{"packages.winterHolidays" | transloco}} 2024
            <!-- <br>
                {{"currentlyNotAvaiable" | transloco}} -->
          </div>
        </div>
      </div>


      <div class="imageRow" [routerLink]="'/occasional-offers/easter' | localize" routerLinkActive="active">
        <div class="background" style="background-image: url('assets/easter.jpg')">
          <div class="textCenter">
            {{"packages.easter" | transloco}}
            <!-- <br>
                {{"currentlyNotAvaiable" | transloco}} -->
          </div>
        </div>
      </div>


      <div class="imageRow">
      <!-- <div class="imageRow" [routerLink]="'/occasional-offers/summer' | localize" routerLinkActive="active"> -->
        <div class="background"
          style="background-image: url('assets/happyfamily2.jpg'); background-position:center bottom;">
          <div class="textCenter">
            {{"packages.summer" | transloco}}
            <br>
            {{"currentlyNotAvaiable" | transloco}}
          </div>
        </div>
      </div>
     

      <div class="imageRow' | localize" routerLinkActive="active">
        <!-- <div class="imageRow" [routerLink]="'/occasional-offers/new-years-eve' | localize" routerLinkActive="active"> -->
        <div class="background" style="background-image: url('assets/sylwester1.jpg')">
          <div class="textCenter">
            {{"packages.newYear" | transloco}} 
            <br>
            {{"currentlyNotAvaiable" | transloco}}
          </div>
        </div>
      </div>


      <div class="imageRow" [routerLink]="'/occasional-offers/winter-recreation' | localize" routerLinkActive="active">
        <!-- <div class="imageRow"> -->
        <div class="background" style="background-image: url('assets/winter-recreation.jpg')">
          <div class="textCenter">
            {{"winter-recreation" | transloco}}
            <br>
              {{"currentlyNotAvaiable" | transloco}}
          </div>
        </div>
      </div>

       <div class="imageRow' | localize" routerLinkActive="active">
        <!-- <div class="imageRow" [routerLink]="'/occasional-offers/christmas' | localize" routerLinkActive="active"> -->
          <div class="background" style="background-image: url('assets/christmas.jpeg')">
            <div class="textCenter">
              {{"packages.christmas" | transloco}} 2024
              <br>
              {{"currentlyNotAvaiable" | transloco}}
            </div>
          </div>
        </div>


      <!-- <div class="imageRow"> -->














      <!-- <div class="imageRow"> -->

      <!-- <div class="imageRow"> -->

    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>