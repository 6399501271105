import { Component, OnInit } from '@angular/core';
import { NavBarService } from '../../navbar.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { AdditionalInformationsComponent } from 'app/shared/components/additional-informations/additional-informations.component';
import { AttractionsComponent } from 'app/shared/components/attractions/attractions.component';
import { LanguageSelectorComponent } from 'app/shared/components/language-selector/language-selector.component';
import { WeProvideComponent } from 'app/shared/components/we-provide/we-provide.component';
import { WeProvideWinterComponent } from 'app/shared/components/we-provide-winter/we-provide-winter.component';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';

@Component({
standalone: true,
imports: [TranslocoModule, CommonModule, WeProvideComponent, WeProvideWinterComponent, AttractionsComponent, AdditionalInformationsComponent, LanguageSelectorComponent, LocalizeRouterModule, RouterModule],
  selector: 'app-christmas',
  templateUrl: './christmas.component.html',
  styleUrls: ['./christmas.component.css']
})
export class ChristmasComponent {

  constructor(
    public navBarService:NavBarService,
    public translate:TranslocoService) { 
      this.navBarService.hide()
    }
 
  onWindowScroll()
  {
    let firstRow = document.getElementById("pageTopRectanglesRow")
    firstRow.style.height = "10%";
    firstRow.style.transition = "1s";
    let secondRow = document.getElementById("pageRectanglesRow")
    secondRow.style.height = "90%"
  }

}
