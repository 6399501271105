import { Component, OnInit } from '@angular/core';
import { NavBarService } from '../navbar.service';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';

@Component({
standalone: true,
imports: [TranslocoModule, CommonModule, LocalizeRouterModule, RouterModule],
  selector: 'app-packets',
  templateUrl: './packets.component.html',
  styleUrls: ['./packets.component.css']
})
export class PacketsComponent {

  constructor(
    public navBarService:NavBarService,
    public translate:TranslocoService)
     { 
      this.navBarService.show()
    }
  
}
