<div class="bgFishTasting" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/organisation-of-events' | localize" routerLinkActive="active">
      {{"backSecond" | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox" style="padding: 20px 20px; text-align: justify;">
     
      <h2>Degustacja ryb</h2>

      18 grudnia 2021r. o godz. 14:00 mieliśmy przyjemność gościć Państwa na otwartej degustacji ryb. Świeże ryby zostały zakupione w kołobrzeskim porcie, prosto z kutra oraz w lokalnej wędzarni, a potrawy przygotowane zostały przez naszą kuchnię.

      <br/>

      Jest nam niezmiernie miło, że odwiedziło nas tak liczne grono smakoszy. Dla nieobecnych mamy skrót i małą fotorelację z tego pysznego dnia. 
      <br/>
      <br/>
      Podczas degustacji serwowane były wyśmienite potrawy z ryb, z polskiego morza: łosoś, dorsz, makrela, śledź, flądra. 
      <br/>
      <br/>
      <b>Doskonały Łosoś/Troć bałtycki</b>: Mięso ścisłe, tłuste, soczyste, pomarańczowo-różowe, wyrazisty smak, małoościste.<br/>
      <b>Cudowny dorsz</b>: Dorsz o mięsie białym, soczystym, delikatnym, łagodnym, słodkawym smaku, mało ościste.<br/>
      <b>Smakowity Turbot</b>: Turbot o delikatnym smaku i białym mięsie.<br/>
      <b>Najszlachetniejsza Sola</b>: Sola o bardzo delikatnym smaku. Jej mięso jest białe, chude, jędrne, łatwo oddziela się od ości.<br/>
      <b>Tłusta Sieja</b>: Sieja mięso białe, delikatne, łatwo odchodzące od nielicznych ości<br/>
      <b>Śledź</b>: Wyrazisty smak Śledzia bałtyckiego o mięsie miękkim,  dość ościste.<br/>
      <b>Soczysta Szprota</b>: Soczysta Szprota mięso ścisłe, soczyste, o dość wyrazistym smaku.<br/>
      <br/>
      <br/>
      Dziękujemy wszystkim, którzy brali udział i mamy nadzieję, że zobaczymy się w przyszłym roku!

      <br/>
      <br/>
      <br/>
      <small>Poczęstunek organizowany był w ramach dofinansowania uzyskanego ze środków Unii Europejskiej oraz PO RYBY 2014-2020 w Ramach Programu „Rybactwo i Morze” na lata 2014-2020.</small> 
    
      
      <a *ngFor="let image of [1,2,3,4,5,6,7]" [href]="'/assets/images/fish-tasting/' + image + '.jpg'" target="_blank">
        <img [src]="'/assets/images/fish-tasting/' + image + '.jpg'" style="width: 100%; height:auto;">
      </a>
    
      <img *ngFor="let image of [8,9]" [src]="'/assets/images/fish-tasting/' + image + '.jpg'" style="width: auto; height:100px;">
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>