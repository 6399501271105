<div class="bg" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/menu' | localize" routerLinkActive="active">{{"backSecond" | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="pageMarginBox BoxFadeInFromRight"></div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <ul class="event-list">
       
          	<li *ngFor="let n of news">
                <time [routerLink]="'/news-details/{{n?.id}}' | localize" routerLinkActive="active">
                  <span class="day">{{n?.day}}</span>
                  <span class="month">{{n?.month}}</span>
                  <span class="year">2014</span>
                  <span class="time">ALL DAY</span>
                </time>
                <img [routerLink]="'/news-details/{{n?.id}}' | localize" routerLinkActive="active" *ngIf="n?.image"  src="{{n?.imageUrl}}" />
                <div [routerLink]="'/news-details/{{n?.id}}' | localize" routerLinkActive="active"  class="info">
                  <h2 class="title">{{n?.title}}</h2>
                  <p class="desc" style="text-align: justify">{{n?.description}}</p>
                </div>
              </li>

				</ul>
      <div style="height:10px;"></div>

    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>