<div class="bgEaster" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/occasional-offers' | localize">{{"backSecond" | transloco}}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h1 class="topic" style="color:#80006b;">{{"packages.easter" | transloco}}</h1>
      <br />
      <h3 class="heading">{{"packageIncludes" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"alimentation" | transloco}}</span>
            <span class="text">{{"breakfastAndDinnes" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"discount" | transloco}}</span>
            <span class="text">{{"discountTenForSPA" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sauna" | transloco}}</span>
            <span class="text">{{"unlimitedSauna" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"aquapark" | transloco}}</span>
            <span class="text">{{"aquaparkAccess" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"forKids" | transloco}}</span>
            <span class="text">{{"playRoom" | transloco}}</span>
          </li>
        </ul>
      </div>
      <h3 class="heading">{{"easterProgram" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"specialAlimentation" | transloco}}</span>
            <span class="text">{{"easterAlimentation" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"sweetBuffet" | transloco}}</span>
            <span class="text">{{"sweetBuffetText" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"game" | transloco}}</span>
            <span class="text">{{"gameText" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"bonfire" | transloco}}</span>
            <span class="text">{{"bonfireText" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"present" | transloco}}</span>
            <span class="text">{{"presentInsideRoom" | transloco}}</span>
          </li>
        </ul>
      </div>
      <app-we-provide></app-we-provide>
      <app-attractions></app-attractions>
      <h3 class="heading">{{"pricePerPerson" | transloco}}</h3>
      <table class="priceTable table table-striped">
        <tbody>
          <tr>
            <td style="text-align: left;">&nbsp;</td>
            <td style="text-align: center;">
              <p>
                {{ "from" | transloco}} 29.03 {{"to" | transloco}} 01.04<br/>
                {{ "from" | transloco}} 30.03 {{"to" | transloco}} 02.04

              </p>
            </td>
          </tr>
          <tr>
            <td class="topicTd" style="text-align: center;">
              {{"threeNights" | transloco}}
            </td>
            <td style="text-align: center;">
              <strong>1019zł</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 class="heading">{{"additionalFees" | transloco}}</h3>
      <div class="infoBox">
        <ul>
          <li>
            <span class="title">{{"kidsUnderFive" | transloco}}</span>
            <span class="text">{{"free" | transloco}} <br />
              {{"withTwoFullyPaid" | transloco}}
            </span>
          </li>
          <li>
            <span class="title">{{"kids-between-5-12" | transloco}}</span>
            <span class="text">509zł</span>
          </li>
          <li>
            <span class="title">{{"addictionalDay" | transloco}}</span>
            <span class="text">229zł</span>
          </li>
          <li>
            <span class="title">{{"extraBed" | transloco}}</span>
            <span class="text">770zł</span>
          </li>
          <li>
            <span class="title">{{"parking" | transloco}}</span>
            <span class="text">30zł / {{"day" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"touristTax" | transloco}}</span>
            <span class="text">2.50zł {{"day" | transloco}}/{{"person" | transloco}}</span>
          </li>
        </ul>
      </div>
      <additional-informations></additional-informations>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>