<h3 class="heading">{{"weProvide" | transloco}}</h3>
      <div class="infoBox">
        <ul>
            <li>
                <span class="title">{{"internet" | transloco}}</span>
                <span class="text">{{"freeInternetAccess" | transloco}}</span>
              </li>
          <li>
            <span class="title">{{"bicycleStorage" | transloco}}</span>
            <span class="text">{{"bicycleStorageText" | transloco}}</span>
          </li>
          <li>
            <span class="title">{{"nordicWalkingPoles" | transloco}}</span>
            <span class="text">{{"nordicWalkingPolesText" | transloco}}</span>
          </li>
          <li>
              <span class="title">{{"beachSet" | transloco}}</span>
              <span class="text">{{"beachSetText" | transloco}}</span>
            </li>
          <li>
            <span class="title">{{"hotelParking" | transloco}}</span>
            <span class="text">{{"hotelParkingText" | transloco}}</span>
          </li>
        </ul>
      </div>