<div class="bg" style="background-image: url('assets/spa1.jpg')" (wheel)="onWindowScroll()">
  <div id="pageTopRectanglesRow">
    <div id="backButton" class="pageMarginBox BoxFadeInFromLeft" [routerLink]="'/spa' | localize" routerLinkActive="active">{{"backSecond"
      | transloco }}</div>
    <div class="pageTopMarginBox">
    </div>
    <div class="centered pageMarginBox BoxFadeInFromRight">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div id="pageRectanglesRow">
    <div class="pageSideMarginBox BoxFadeInFromLeft"></div>
    <div id="content" class="pageContentBox">
      <h1>
        {{"massages" | transloco}}
      </h1>
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <div style="overflow-x:auto;">
            <table style="width:100%;">
              <tbody>
                <tr style="font-weight: 900;">
                  <td>
                    {{"massage" | transloco}}
                  </td>
                  <td>
                    {{"duration" | transloco}}
                  </td>
                  <td>
                    {{"priceIn" | transloco}} PLN
                  </td>
                </tr>
                <tr style="height:20px;">
                  <td colspan="3"></td>
                </tr>
              
                <tr>
                  <td>{{"spaMassages.classicPartly" | transloco}}</td>
                  <td> 15 min</td>
                  <td> 55 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.classicSpine" | transloco}} </td>
                  <td> 25 min</td>
                  <td> 90 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.classicLowerLimbs" | transloco}}</td>
                  <td> 30 min</td>
                  <td> 90 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.overallRelaxation" | transloco}}</td>
                  <td> 50 min</td>
                  <td> 150 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.partialRelaxation" | transloco}} </td>
                  <td> 25 min</td>
                  <td> 90 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.warmChocolateOverall" | transloco}}</td>
                  <td> 50 min</td>
                  <td> 190 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.warmChocolatePartly" | transloco}}</td>
                  <td> 25 min</td>
                  <td> 100 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.slimmingOverall" | transloco}}</td>
                  <td> 50 min</td>
                  <td> 190 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.slimmingPartly" | transloco}} </td>
                  <td> 25 min</td>
                  <td> 100 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.aromaticOverall" | transloco}}</td>
                  <td> 50 min</td>
                  <td> 150 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.aromaticPartly" | transloco}}</td>
                  <td> 25 min</td>
                  <td> 80 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.footReflexology" | transloco}}</td>
                  <td> 30 min</td>
                  <td> 90 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.earCandling" | transloco}}</td>
                  <td> 30 min</td>
                  <td> 90 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.hotStonesOverall" | transloco}}</td>
                  <td> 60 min</td>
                  <td> 190 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.hotStonesPartly" | transloco}}</td>
                  <td> 30 min</td>
                  <td> 100 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.aromaticHotCandleOverall" | transloco}}</td>
                  <td> 50 min</td>
                  <td> 190 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.aromaticHotCandlePartly" | transloco}}</td>
                  <td> 25 min</td>
                  <td> 100 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.sheaButter" | transloco}}</td>
                  <td> 50 min</td>
                  <td> 180 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.lymphaticDrainage" | transloco}}</td>
                  <td> 50 min</td>
                  <td> 180 zł</td>
                </tr>
                <tr>
                  <td>{{"spaMassages.lymphaticDrainageOfTheLegs" | transloco}} </td>
                  <td> 25 min</td>
                  <td> 95 zł</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style="height:10px;"></div>
    </div>
    <div class="pageSideMarginBox BoxFadeInFromRight"></div>
  </div>
</div>